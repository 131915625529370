import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {faFacebook,faInstagram,faLinkedin} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (

      <footer className=''>

      <div className=' bg-[#f5ecfd] py-[2rem] '>

      <div className="flex mx-[6rem] flex-wrap  ">

        <div className="md:flex-grow-[2] flex flex-col p-2 md:p-4 m-2">
          <p className=' text-[1rem] md:text-[1.5rem] font-serif font-semibold'>Coursewallah</p>
          <p className=' text-[0.6rem] md:text-[0.9rem] mt-2'>GET YOURSELF READY FOR YOUR DREAM JOB/INTERNSHIP IN 60 DAYS</p>
          <div className=' text-[1.2rem] flex gap-8  mt-5 text-[#006599]'>
            <a href="http://facebook.com/coursewallah">
            <FontAwesomeIcon className=' transition ease-in-out duration-200 ' icon={faFacebook} />

            </a>
            <a href="https://www.instagram.com/iamcoursewallah/">
            <FontAwesomeIcon className=' transition ease-in-out duration-200 ' icon={faInstagram} />

            </a>
            <a href="https://www.linkedin.com/company/coursewallah/about/">
            <FontAwesomeIcon className=' transition ease-in-out duration-200 ' icon={faLinkedin} />

            </a>

          </div>
          
        </div>

        <div className="flex-grow p-2  md:p-4 m-2">
          <p className=' text-[1rem] md:text-[1.5rem] font-serif font-semibold'>Quick links</p>
          
          <ul className='text-[0.8rem] md:text-[1rem] mt-2'>
            <li ><Link to='/home' className='hover:underline'>Home</Link></li>
            <li className='mt-1'><Link to='/aboutus' className='hover:underline'>About Us</Link></li>
            <li className='mt-1'><Link to='/contactus' className='hover:underline'>Contact Us</Link></li>

          </ul>
        </div>

        <div className="flex-grow p-2  md:p-4 m-2 ">
        <p className=' text-[1rem] md:text-[1.5rem] font-serif font-semibold'>Contact Us</p>
          
          <ul className='text-[0.8rem] md:text-[1rem] mt-2'>
            <li >Ph.no: +91 8319883507</li>
            <li className='mt-1'>Email: info@coursewallah.com</li>
            
          </ul>
        </div>

        

      </div>

      <div  className='pt-[1rem] border-t-[1px] border-t-[#856f91] text-[0.8rem] md:text-[1rem]'>
        
        <div className='flex gap-4 justify-center'>
          <div><Link to="/privacypolicy" className='hover:underline'>Privacy Policy</Link></div>
          <div><Link to="/termsandconditions" className='hover:underline'>Terms And Conditions</Link></div>
          <div><Link to="/returnandrefund" className='hover:underline'>Return and Refund Policy</Link></div>
        </div>
        <div className='text-center mt-4'>
          ©2021 All rights reserved
        </div>
      </div>

      </div>
      </footer>
  );
}

export default Footer;

