import React, { useEffect, useState } from 'react';
import { FaHeart, FaRegHeart, FaSearch, FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuth } from '../services/AuthProvider';
import { apiUrl } from '../constants/ApplicationConstants';
import axios from 'axios';

function EnrolledCourses({ CourseAssign = 'courses' }) {

  const [courses, setCourses] = useState([]);
  const { currentUser, getCurrentUser } = useAuth();
  const [reqsent, setReqsent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const [likedCourses, setLikedCourses] = useState(currentUser.likedCourses || []);
  const [searchQuery, setSearchQuery] = useState('')
  const [newCourse, setNewCourse] = useState({
    title: '',
    rating: 5,
    price: 2000,
    imageUrl: '',
    meetUrl: '',
    durationHours: 2,
    durationMinutes: 20
  });
  const [error, setError] = useState({
    title: '',
    price: '',
    meetUrl: '',
    general: '',
  });

  const [postImage, setPostImage] = useState({ myFile: "" })
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file && file.size > maxSize) {
      alert("File Size Exceeds 2MB, please upload smaller image")
      e.target.value = '';
      return;
    }
    const base64 = await convertToBase64(file);
    setPostImage({ ...postImage, myFile: base64 })
    setNewCourse({ ...newCourse, imageUrl: base64 })
  }

  const handleAddCourseClick = () => {
    setNewCourse({
      title: '',
      rating: 5,
      price: 2000,
      imageUrl: '',
      meetUrl: '',
      durationHours: 2,
      durationMinutes: 20
    });
    setPostImage({ myFile: "" })
    setEditingCourse(null); // Reset editing course
    setIsModalOpen(true);
    setError({ title: '', price: '', meetUrl: '', general: '' });
  };

  const handleEditCourseClick = (course) => {
    setNewCourse({
      title: course.title,
      rating: course.rating,
      price: course.price,
      imageUrl: course.imageUrl,
      meetUrl: course.meetUrl,
      durationHours: course.durationHours,
      durationMinutes: course.durationMinutes
    });
    setPostImage({ myFile: "" })
    setEditingCourse(course);
    setIsModalOpen(true);
    setError({ title: '', price: '', meetUrl: '', general: '' });
  };

  const handleCloseModal = () => {
    setPostImage({ myFile: "" })
    setIsModalOpen(false);
    setEditingCourse(null); // Reset editing course
    setError({ title: '', price: '', meetUrl: '', general: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCourse({ ...newCourse, [name]: value });

    if (name === 'title' && error.title) {
      setError({ ...error, title: '' });
    }
    if (name === 'price' && error.price) {
      setError({ ...error, price: '' });
    }
    if (name === 'meetUrl' && error.meetUrl) {
      setError({ ...error, meetUrl: '' });
    }
  };

  const validateFields = () => {
    let valid = true;
    const newError = { title: '', price: '', meetUrl: '', general: '' };

    if (newCourse.title.length < 3 || newCourse.title.length > 30) {
      newError.title = 'Title must be between 3 and 50 characters.';
      valid = false;
    }
    if (isNaN(newCourse.price) || newCourse.price <= 0) {
      newError.price = 'Price must be a positive number.';
      valid = false;
    }

    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/; // Basic URL pattern
    if (newCourse.meetUrl && !urlPattern.test(newCourse.meetUrl)) {
      newError.meetUrl = 'Invalid Meet URL format.';
      valid = false;
    }

    setError(newError);
    return valid;
  };

  const handleAddCourse = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    setReqsent(true);
    try {
      if (!newCourse.imageUrl || newCourse.imageUrl === '') {
        newCourse.imageUrl = 'https://t4.ftcdn.net/jpg/06/33/18/45/360_F_633184577_48ADNiowpMXHf3dJqZgN9P2XBlW1vGFH.jpg';
      }
      const response = await axios.post(apiUrl + '/course', {
        title: newCourse.title,
        instructor: currentUser._id,
        instructorName: currentUser.userName,
        rating: newCourse.rating,
        price: newCourse.price,
        imageUrl: newCourse.imageUrl,
        meetUrl: newCourse.meetUrl,
        durationHours: newCourse.durationHours,
        durationMinutes: newCourse.durationMinutes,
        timeline: []
      }, { withCredentials: true });

      const data = await response.data;
      console.log('Course created successfully:', data);
      setReqsent(false);
      setIsModalOpen(false);
      // window.location.reload()
      fetchCoursesOfSelf();
      await getCurrentUser();
    } catch (error) {
      console.error('Error adding course:', error);
      setError({ ...error, general: 'There was an issue adding the course. Please try again.' });
      setReqsent(false);
    }
  };

  const handleEditCourse = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;
    setReqsent(true);
    try {
      const response = await axios.patch(`${apiUrl}/course/${editingCourse._id}`, {
        title: newCourse.title,
        rating: newCourse.rating,
        price: newCourse.price,
        imageUrl: newCourse.imageUrl,
        meetUrl: newCourse.meetUrl,
        durationHours: newCourse.durationHours,
        durationMinutes: newCourse.durationMinutes
      }, { withCredentials: true });

      const data = await response.data;
      console.log('Course updated successfully:', data);
      setReqsent(false);
      setIsModalOpen(false);
      // window.location.reload()
      fetchCoursesOfSelf();
      await getCurrentUser();
    } catch (error) {
      console.error('Error updating course:', error);
      setError({ ...error, general: 'There was an issue updating the course. Please try again.' });
      setReqsent(false);
    }
  };
  const likeCourse = async (courseId) => {
    try {
      const response = await axios.post(`${apiUrl}/user/likeCourse`, {
        courseId: courseId,
        userId: currentUser._id
      }, { withCredentials: true });

      const data = await response.data;
      console.log(data);

      setLikedCourses([...likedCourses, courseId]);

    } catch (error) {
      console.error('Error Liking course:', error);
    }
  };

  const unlikeCourse = async (courseId) => {
    try {
      const response = await axios.post(`${apiUrl}/user/unlikeCourse`, {
        courseId: courseId,
        userId: currentUser._id
      }, { withCredentials: true });

      const data = await response.data;
      console.log(data);

      setLikedCourses(likedCourses.filter(id => id !== courseId));

    } catch (error) {
      console.error('Error Unliking course:', error);
    }
  };
  const fetchCoursesOfSelf = () => {
    if (currentUser.role === "Admin") {

      const fetchAllCourses = async () => {
        try {
          const response = await axios.get(apiUrl + '/course', { withCredentials: true });
          const data = response.data;
          setCourses(data);
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      };
      fetchAllCourses();
    }
    else if (currentUser.role === "Student") {
      const fetchEnrolledCourses = async () => {
        try {
          const response = await axios.post(`${apiUrl}/course/getEnrolledCourses`, {
            userId: currentUser._id
          }, { withCredentials: true });
          const data = response.data;
          setCourses(data);
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
      fetchEnrolledCourses();
    }
    else {
      const fetchEnrolledCourses = async () => {
        try {
          const response = await axios.post(`${apiUrl}/course/getTeachingCourses`, {
            userId: currentUser._id
          }, { withCredentials: true });
          const data = response.data;
          setCourses(data);
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      }
      fetchEnrolledCourses();
    }
  }
  useEffect(() => {
    fetchCoursesOfSelf();
  }, []);

  return (
    <div className="p-1 border-gray-300 border-[1px] rounded-3xl w-full my-3">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-semibold m-3">{CourseAssign[0].toUpperCase() + CourseAssign.slice(1)}</h1>
        {(currentUser.role === "Admin" || currentUser.role === "Instructor") && (
          <button
            className="cursor-pointer hover:bg-[#ffcbff] rounded-full w-fit bg-[#ffebff] p-2 mx-3 text-center"
            onClick={handleAddCourseClick}
          >
            <span className="mx-2 font-medium">Add Course</span>
          </button>
        )}
      </div>
      <div className='p-3'>
        <div className='m-3 flex items-center rounded-full border-[1px] border-gray-300 p-3'>
          <FaSearch className='mx-3' size={20} color='#ffabff' />
          <input value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} className='w-full focus:outline-none outline-none' type="text" placeholder='Search Courses...' />
        </div>
        <div className="flex flex-wrap justify-evenly w-full">
          {courses.filter((e) => e.title.toLowerCase().includes(searchQuery.toLowerCase()) || e.instructorName.toLowerCase().includes(searchQuery.toLowerCase())).map((course, index) => (
            <div
              key={index}
              className="w-full md:w-full lg:w-[46%] xl:w-[46%] 2xl:w-[46%] m-1 border-gray-300 border-[1px] bg-white p-4 rounded-2xl shadow-md"
            >
              <div className='flex items-center justify-between'>
                <p className="text-sm text-black bg-[#ffebff] mb-3 flex w-fit p-1 rounded-full px-3">
                  By {course?.instructorName}
                </p>
                {(currentUser?.role === "Admin" || (currentUser?.role === "Instructor" && course?.instructor === currentUser._id)) && (
                  <button
                    className="text-sm text-black bg-[#ffebff] mb-3 flex w-fit p-1 rounded-full px-3"
                    onClick={() => handleEditCourseClick(course)}
                  >
                    Edit
                  </button>
                )}
              </div>
              <h3 className="text-2xl font-medium">{course.title}</h3>
              <div className="flex items-center mt-4">
                <p className="text-xs mr-3 text-black bg-white rounded-full border-gray-400 border-[1px] p-1 px-2">
                  {course.durationHours}h {course.durationMinutes}min
                </p>
                <p className="text-xs ml-3 text-black bg-white rounded-full border-gray-400 border-[1px] p-1 px-2 flex items-center">
                  <FaStar color="gold" />
                  {(course?.ratedBy?.reduce((acc, curr) => acc + curr.rating, 0)) / (course?.ratedBy?.length) || 5}
                </p>
              </div>
              <img
                src={course.imageUrl}
                alt={course.title}
                className="w-full h-40 object-cover my-4 rounded-lg"
              />
              <div className="flex items-center">
                <div onClick={() => {
                  if (likedCourses.includes(course._id)) {
                    unlikeCourse(course._id);
                  } else {
                    likeCourse(course._id);
                  }
                }} className="rounded-full bg-[#ffebff] p-3 mx-3 hover:bg-[#ffcbff] cursor-pointer">
                  {likedCourses.includes(course._id) ?
                    <FaHeart color='violet' /> : <FaRegHeart />}
                </div>
                <Link
                  to={`/dashboard/${CourseAssign}/${course.url}`}
                  className="cursor-pointer hover:bg-[#ffcbff] rounded-full w-full bg-[#ffebff] p-2 mx-3 text-center"
                >
                  <span className="mx-2 font-medium">{CourseAssign === "courses" ? 'View More' : 'Add Assignment'}</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for Adding/Editing Course */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <form
            onSubmit={editingCourse ? handleEditCourse : handleAddCourse}
            className="bg-white p-6 pb-2  h-fit max-h-[90vh] overflow-scroll"
          >
            <h2 className="text-2xl font-semibold mb-4">
              {editingCourse ? 'Edit Course' : 'Add New Course'}
            </h2>

            {/* Title Input */}
            <label className="block mb-2 font-medium">Course Title</label>
            <input
              type="text"
              name="title"
              value={newCourse.title}
              onChange={handleInputChange}
              placeholder="Course Title"
              className="w-full mb-4 p-2 border rounded"
              maxLength={30}
              required
            />
            {error.title && <p className="text-red-500 text-sm">{error.title}</p>}

            {/* Time Input */}
            {/* <label className="block mb-2 font-medium">Total Time</label>
            <input
              type="text"
              name="time"
              value={newCourse.time}
              onChange={handleInputChange}
              placeholder="3h 20min"
              className="w-full mb-4 p-2 border rounded"
              required
              /> */}
            <label className="block mb-2 font-medium">Total Duration</label>
            <div className='flex items-center justify-evenly w-full'>
              <div>
                <input
                  type="number"
                  name="durationHours"
                  value={newCourse.durationHours}
                  onChange={handleInputChange}
                  className="mb-4 p-2 border rounded"
                  required
                  min={0}
                  max={100}
                />
                hrs

              </div>
              <div>

                <input
                  type="number"
                  name="durationMinutes"
                  value={newCourse.durationMinutes}
                  onChange={handleInputChange}
                  className="mb-4 p-2 border rounded"
                  required
                  min={0}
                  max={59}
                />min
              </div>
            </div>

            {/* Rating Input */}
            {/* <label className="block mb-2 font-medium">Rating (out of 5)</label>
            <input
              type="number"
              name="rating"
              value={newCourse.rating}
              onChange={handleInputChange}
              placeholder="Rating (out of 5)"
              className="w-full mb-4 p-2 border rounded"
              required
              min={0}
              max={5}
            /> */}

            {/* Price Input */}
            <label className="block mb-2 font-medium">Price</label>
            <input
              type="number"
              name="price"
              value={newCourse.price}
              onChange={handleInputChange}
              placeholder="Price"
              className="w-full mb-4 p-2 border rounded"
              required
              max={99999}
            />
            {error.price && <p className="text-red-500 text-sm">{error.price}</p>}
            {/* Image URL Input */}
            <label className="block mb-2 font-medium">Image</label>
            <label htmlFor="file-upload" className='custom-file-upload'>
              {/* <img width={150} src={currentUser?.profilePhoto || avatar} alt="" /> */}
              <img width={150} src={postImage?.myFile || newCourse?.imageUrl} alt="" />
            </label>

            <input
              type="file"
              lable="Image"
              name="myFile"
              id='file-upload'
              accept='.jpeg, .png, .jpg'
              onChange={(e) => handleFileUpload(e)}
            />

            {/* Meet URL Input */}
            <label className="block mb-2 font-medium">Meet URL</label>
            <input
              type="text"
              name="meetUrl"
              value={newCourse.meetUrl}
              onChange={handleInputChange}
              placeholder="Meet URL"
              className="w-full mb-4 p-2 border rounded"
              required
            />
            {error.meetUrl && <p className="text-red-500 text-sm">{error.meetUrl}</p>}

            {error.general && <p className="text-red-500 text-sm mt-2">{error.general}</p>}
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleCloseModal}
                className="mr-4 bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-purple-400 text-white px-4 py-2 rounded hover:bg-blue-600"
                disabled={reqsent}
              >
                {editingCourse ? 'Update Course' : 'Add Course'}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default EnrolledCourses;


function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result)
    };
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}