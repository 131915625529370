import React, { useEffect, useState } from 'react'
import refimg from '../assets/dashboard/5.png'
import refimg2 from '../assets/dashboard/6.png'
import { FaInstagram, FaLink, FaTelegram, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { Link } from '@mui/material'
import MyReferrals from '../constants/MyReferrals'
import { useAuth } from '../services/AuthProvider'
import axios from 'axios'
import { apiUrl } from '../constants/ApplicationConstants'

const Referrals = () => {
    const { currentUser } = useAuth();
    const [referralsList, setReferralsList] = useState([])
    const [linkCopy, setLinkCopy] = useState(currentUser.refferalCode)
    useEffect(() => {
      const fetchReferrals = async ()=>{
        try {
            const response = await axios.post(`${apiUrl}/user/getReferrals`,{
                userId:currentUser._id
            },{withCredentials:true});
            const data = await response.data;
            setReferralsList(data);
        } catch (error) {
            console.log(error);
        }
      }
      fetchReferrals()
    }, [])
    
    return (
        <>
            <div className='relative flex items-center justify-center w-full h-[300px] bg-gray-100 rounded-3xl'>
                <img className='hidden md:hidden lg:flex xl:flex 2xl:flex h-full absolute left-0 opacity-80' src={refimg} alt="" />
                <img className='hidden md:hidden lg:flex xl:flex 2xl:flex h-full absolute right-0 opacity-80' src={refimg2} alt="" />
                <div className='p-3 flex flex-col justify-center items-center '>
                    <h1 className='font-semibold text-xl my-3'>Refer a Friend</h1>
                    <div className='rounded-full p-3 border-[1px] border-pink-300 flex justify-center items-center'>
                        <FaLink size={15} color='#ff5eea' />
                        <a className='mx-4 text-xs md:text-xs lg:text-sm xl:text-sm 2xl:text-sm cursor-pointer' onClick={(e) => {
                            navigator.clipboard.writeText(currentUser.refferalCode); setLinkCopy("Copied to Clipboard"); setTimeout(() => {
                                setLinkCopy(currentUser.refferalCode)
                            }, 1000);
                        }}>Coupon Code: {linkCopy}</a>
                    </div>
                </div>
            </div>
            <div className='flex flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row'>
                <div className="p-5 w-[47%] mx-2 border-gray-300 border-[1px] rounded-3xl w-full my-3">
                    <h2 className='font-semibold text-xl my-3'>Share the Referral Code</h2>
                    <p className='text-sm'>Invite friends and earn exclusive rewards for every signup!</p>
                    <div className='flex flex-wrap py-5 justify-evenly'>
                        <a className='text-sm hover:bg-[#ffcbff] rounded-full px-3 py-2 border-[1px] border-pink-300 flex justify-center items-center cursor-pointer' onClick={(e) => {
                            navigator.clipboard.writeText(currentUser.refferalCode)
                        }}>Copy Link</a>
                        <div className='rounded-full hover:bg-[#ffcbff] m-1 px-3 py-2 border-[1px] border-pink-300 flex justify-center items-center cursor-pointer'>
                            <FaWhatsapp color='green' />
                        </div>
                        <div className='rounded-full hover:bg-[#ffcbff] m-1 px-3 py-2 border-[1px] border-pink-300 flex justify-center items-center cursor-pointer'>
                            <FaTwitter color='#429ef5' />
                        </div>
                        <div className='rounded-full hover:bg-[#ffcbff] m-1 px-3 py-2 border-[1px] border-pink-300 flex justify-center items-center cursor-pointer'>
                            <FaInstagram color='purple' />
                        </div>
                        <div className='rounded-full hover:bg-[#ffcbff] m-1 px-3 py-2 border-[1px] border-pink-300 flex justify-center items-center cursor-pointer'>
                            <FaTelegram color='#429ef5' />
                        </div>
                    </div>
                </div>
                <div className="p-5 w-[47%] mx-2 border-gray-300 border-[1px] rounded-3xl w-full my-3">
                    <h2 className='font-semibold text-4xl mt-2 '><span className='text-pink-500'>78</span><span className='text-xl'>%</span></h2>
                    <h2 className='font-semibold text-sm text-gray-400 mb-3'>Successful Referrals</h2>
                    <p className='text-sm'>Join the majority who are already benefiting from our referral program. Your next successful referral could be just a click away.</p>
                </div>
            </div>
            <div className="p-5 mx-2 border-gray-300 border-[1px] rounded-3xl w-full my-3">
                <div className='flex flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row justify-between'>
                    <h1 className='text-lg md:text-lg lg:text-3xl xl:text-3xl 2xl:text-3xl font-semibold m-3'>Your Referrals</h1>
                    <div className='flex flex-row md:flex-row lg:flex-col xl:flex-col 2xl:flex-col items-center mx-3'>
                        <p className='text-sm text-gray-500'>Total Rewards</p>
                        <h1 className='text-3xl font-semibold  text-pink-500'>₹{referralsList?.length*50}</h1>
                    </div>
                </div>
                <div className="p-4 font-poppins w-full overflow-scroll ">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b text-left text-base font-semibold text-gray-700">Name</th>
                                {/* <th className="py-2 px-4 border-b text-left text-base font-semibold text-gray-700">Email</th> */}
                                {/* <th className="py-2 px-4 border-b text-left text-base font-semibold text-gray-700">Date Referred</th> */}
                                {/* <th className="py-2 px-4 border-b text-left text-base font-semibold text-gray-700">Status</th> */}
                                <th className="py-2 px-4 border-b text-left text-base font-semibold text-gray-700">Reward</th>
                            </tr>
                        </thead>
                        <tbody>
                            {referralsList.map((referral, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="text-sm py-2 px-4 border-b text-gray-700">{referral.userName}</td>
                                    {/* <td className="text-sm py-2 px-4 border-b text-gray-700">{referral.email}</td> */}
                                    {/* <td className="text-sm py-2 px-4 border-b text-gray-700">{referral.dateReferred}</td> */}
                                    {/* <td
                                        className={`py-2 px-4 border-b text-sm font-medium ${referral.status === "Joined" ? "text-green-500" : "text-yellow-500"
                                            }`}
                                    >
                                        {referral.status}
                                    </td> */}
                                    <td className="text-sm py-2 px-4 border-b text-gray-700">
                                        ₹50
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Referrals