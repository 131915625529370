import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaRegHeart, FaSearch, FaStar } from 'react-icons/fa';
import { apiUrl } from '../constants/ApplicationConstants';
import { useAuth } from '../services/AuthProvider';

function RecommendedCourses() {
  const [courses, setCourses] = useState([])
  const [refCode, setRefCode] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [refError, setRefError] = useState({message:''})
  const { currentUser,getCurrentUser } = useAuth();
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const enrollInCourse = async (e) => {
    e.preventDefault();
    if (refCode === currentUser.referralCode) {
      setRefError({ message: "You cannot refer yourself" });
      return;
    }
  
    try {
      const orderResponse = await axios.post(`${apiUrl}/payment/createOrder`, {
        amount: selectedCourse.price,
        currency: 'INR',
      },{withCredentials:true});
  
      const { id: order_id, amount, currency } = orderResponse.data;
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount,
        currency: currency,
        name: selectedCourse.title,
        description: 'Course Enrollment',
        order_id: order_id,
        handler: async (response) => {
          const paymentData = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };
  
          try {
            await axios.post(`${apiUrl}/payment/verifyPayment`, paymentData,{withCredentials:true});
            console.log('verified');
            await axios.post(`${apiUrl}/course/enrollInCourse`, {
              userId: currentUser._id,
              courseId: selectedCourse._id,
              referralCode: refCode,
            }, { withCredentials: true });
  
            alert('Payment Successful, Enrolled in Course!');
            setIsModalOpen(false);
            setSelectedCourse(null);
            await fetchAllCourses();
            await getCurrentUser();
          } catch (error) {
            console.log('Payment verification failed:', error);
          }
        },
        prefill: {
          name: currentUser.name,
          email: currentUser.email,
          contact: currentUser.contact,
        },
        theme: {
          color: '#ffcbff',
        },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log('Error in Razorpay payment:', error);
      alert("Payment failed")
    }
  };
  
  const checkReferral = async (e)=>{
    e.preventDefault();
    if(refCode==='') {
      setRefError({message:"Enter Referral Code"});
      return;
    }
    if(refCode===currentUser.refferalCode){
      setRefError({message:"You cannot refer yourself"});
      return;
    }
    try {
      const response = await axios.post(`${apiUrl}/user/checkReferral`,{
        refCode,userId:currentUser._id
      },{withCredentials:true});
      const data = await response.data;
      setRefError(data);
      setTimeout(() => {
        setRefError('')
      }, 3000);
    } catch (error) {
      console.log('There is some Error');
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  

  const fetchAllCourses = async () => {
    try {
      const response = await axios.get(apiUrl + '/course', { withCredentials: true });
      const data = response.data;
      setCourses(data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };
  useEffect(() => {
    fetchAllCourses();
  }, [])

  return (
    <div className="p-1  border-gray-300 border-[1px] rounded-3xl w-full my-3">
      <h1 className='text-3xl font-semibold m-3'>Recommended Courses</h1>
      <div className='p-3'>
        <div className='m-3 flex items-center rounded-full border-[1px] border-gray-300 p-3'>
          <FaSearch className='mx-3' size={20} color='#ffabff' />
          <input className='w-full searchInput' value={searchQuery} onChange={(e)=>{setSearchQuery(e.target.value)}} type="text" placeholder='Search Courses...' />
        </div>
      </div>
      <div className='flex flex-wrap justify-evenly w-full'>
        {courses.filter((e)=>e.title.toLowerCase().includes(searchQuery.toLowerCase()) || e.instructorName.toLowerCase().includes(searchQuery.toLowerCase())).map((course, index) => (
          <div key={index} className="w-full md:w-full lg:w-[46%] xl:w-[46%] 2xl:w-[46%] m-1  border-gray-300 border-[1px] bg-white p-4 rounded-2xl shadow-md">
            <p className="text-sm text-black bg-[#ffebff] mb-3 flex w-fit p-1 rounded-full px-3">By {course.instructorName}</p>
            <h3 className="text-2xl font-medium">{course.title}</h3>
            <div className="flex  items-center mt-4">
              <p className="text-xs mr-3 text-black bg-white rounded-full border-gray-400 border-[1px] p-1 px-2">{course.durationHours}h {course.durationMinutes}min</p>
              <p className="text-xs ml-3 text-black bg-white rounded-full border-gray-400 border-[1px] p-1 px-2 flex items-center"><FaStar color='gold' />{course.rating}</p>
            </div>
            <img src={course.imageUrl} alt={course.title} className="w-full h-40 object-cover my-4 rounded-lg" />
            <div className='flex items-center'>
              <div className='rounded-full bg-[#ffebff] p-3 mx-3 cursor-pointer hover:bg-[#ffcbff]'>
                <FaRegHeart />
              </div>
              {(currentUser.role === "Student" && currentUser.enrolledCourses.includes(course._id)) ? <button
                className="bg-[#ffebff] text-black px-4 py-2 rounded-full hover:bg-[#ffcbff]">
                Enrolled
              </button> :
                <button onClick={() => { setIsModalOpen(true); setSelectedCourse(course) }} className='rounded-full w-full hover:bg-[#ffcbff] cursor-pointer bg-[#ffebff] p-2 mx-3 text-center'>
                  <span className='mx-2 font-medium'>Enroll Now</span>
                  <span className="font-semibold text-xl text-gray-800">₹{course.price}</span>
                </button>}
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <div className="fixed flex flex-col inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <form
            onSubmit={enrollInCourse}
            className="bg-white p-6 w-fit h-fit  rounded-lg"
          >
            <h2 className='mb-3 font-semibold text-lg'>Are you sure you want to enroll in {selectedCourse.title} Course?</h2>
            <h2 className='mb-3'>Price : ₹{selectedCourse.price}</h2>
            <div className='mb-3'>
              <label className="block mb-2 font-medium">Referral Code</label>
              <div className='flex items-center'>
                <input
                  type="text"
                  name="code"
                  value={refCode}
                  onChange={(e)=>setRefCode(e.target.value)}
                  placeholder="JdCmhrn"
                  className="w-full p-2 border rounded"
                />
                <button onClick={checkReferral} className='bg-purple-300 p-2'>Redeem</button>
              </div>
            </div>
            <p className='mb-2'>{refError?.message}</p>
            <div className="flex justify-evenly">
              <button
                type="submit"
                className="bg-purple-400 text-white px-4 py-2 rounded hover:bg-blue-600">
                Enroll In Course
              </button>
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="mr-4 bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default RecommendedCourses;
