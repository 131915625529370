import React from 'react'
import { Outlet } from 'react-router-dom'

const MyCoursesHome = () => {
  return (
    <>
    <Outlet/>
    {/* <EnrolledCourses/> */}
    {/* <CourseTimeline schedule={ourCoursesData} /> */}
    </>
  )
}

export default MyCoursesHome