import myimage from "../assets/coverimg.png";
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useContext } from "react";
import { ScrollContext } from "../App";

const Cover = () => {
  const { scrollToSection } = useContext(ScrollContext);

  return (
    <div className=" mx-[50px]  flex-col md:flex-row flex justify-between mt-4 montserrat-regular">
      <div className="md:flex-1 flex flex-col justify-end ">
        <h2 className="montserrat-regular font-semibold sm:leading-[60px] sm:text-[50px] leading-[40px] text-[30px] md:leading-[90px] md:text-[80px] ">Take student experience to the next level</h2>
        <p className="mt-4 sm:text-[1.5rem] text-[#4e4e4e]">Still Confused?<br /> Get your tech career started with <span className="font-semibold text-[#8f46de] uppercase">Coursewallah</span></p>
        <button onClick={scrollToSection} className="w-[200px] rounded-tl-[15px] rounded-br-[15px] mb-20 mt-6 text-[0.7rem] md:text-[1.2rem] bg-[#8f46de] text-white py-3 ">
          LEARN NOW
          <FontAwesomeIcon className="ml-4 transform hover:translate-x-2 transition duration-300" icon={faAngleRight} />
        </button>
      </div>
      <div className=" md:flex-1 md:flex md:items-center ">
        <img src={myimage} className=" w-full object-cover " alt="" />
      </div>
    </div>
  )
}

export default Cover
