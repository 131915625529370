import { createContext, useContext, useEffect, useState } from "react";
import { isLoggedIn } from "./AuthenticationService";
import axios from "axios";
import {useNavigate} from "react-router-dom"
const AuthContext = createContext()
const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    useEffect(() => {
        console.log("UseEffect for AuthProvider, isAuth => ", isAuthenticated)
        if (!isAuthenticated) {
            getCurrentUser();
        }
    }, [isAuthenticated]);

    const getCurrentUser = async () => {
        try {
            const response = await axios.post('/checkIfuserloggedIn');
            setIsAuthenticated(true);
            setCurrentUser(response.data);
        } catch (error) {
            setIsAuthenticated(false);
            setCurrentUser(null);
        }
    };

    const logout = async () => {
        try {
          await axios.post('/logout');  // Call your backend logout endpoint
          setIsAuthenticated(false);   // Reset authentication state
          setCurrentUser(null);        // Clear current user
          navigate('/');      // Redirect to login page
        } catch (error) {
          console.error('Error logging out', error);
        }
      };
    

    return <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, setCurrentUser, currentUser,logout,getCurrentUser }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider }

export const useAuth = () => useContext(AuthContext);