import Slider from "react-slick";
import React, { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import test1 from "../assets/testimonails/test1.png"
import test2 from "../assets/testimonails/test2.png"
import test3 from "../assets/testimonails/test3.png"
import test4 from "../assets/testimonails/test4.png"
import test5 from "../assets/testimonails/test5.png"
import test6 from "../assets/testimonails/test6.png"


const data = [
  {
    name: "Parul Agrawal",
    company: "Cognizant",
    review: "Coursewallah provided structured and high-quality learning with top-notch mentors, quick TAs, and a supportive community. It was the best decision I made.",
    img: test1
  },
  {
    name: "Ankit Giri",
    company: "ICICI Bank",
    review: "With basic coding skills, Coursewallah was a game changer. Their video lessons and structured approach made complex topics clear and were crucial for my interview success.",
    img: test2
  },
  {
    name: "Himanshu Ranjan",
    company: "Axis Bank",
    review: "As a college freshman, I was lost on Data Structures. Coursewallah's course made a huge difference, helping me ace my Myntra interview with what I learned.",
    img: test6
  },
  {
    name: "Abhishek Gupta",
    company: "Cognizant",
    review: "Coursewallah’s DSA course is well-structured with clear explanations and various problem-solving approaches. It covers all essential interview concepts at a student-friendly price.",
    img: test5
  },
  {
    name: "Anand Raut",
    company: "BNY Mellon",
    review: "The DSA self-paced course was instrumental in my technical interview preparation. Clear explanations and practical problems boosted my confidence, leading to a job at Oracle.",
    img: test3
  },
  {
    name: "Ghanshyam Meena",
    company: "Genpact",
    review: "This course covers basics to advanced levels of data structures and algorithms thoroughly. Well-prepared lectures and practice sessions greatly boosted my confidence.",
    img: test4
  }
];

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className}`}
      onClick={onClick}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    />
  );
};


function AutoPlay() {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const settings = {
    dots: true,
    infinite: true,
    // slidesToShow: isMobile ? 1 : 2,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };
  return (
    
    <div className="bg-[#ffe8ee] py-[50px]">


      <div className="text-[3rem] md:text-[4rem] text-center font-semibold p-4  montserrat-regular">
        Testimonials
      </div>


      <div className="slider-container  m-auto w-3/4  pb-10">
        <Slider {...settings} >
        {data.map((d,i)=>{
              return (
                <Card key={i} name={d.name} company={d.company} review={d.review} img={d.img}/>
              )
            })}
        </Slider>

      </div>
    </div>
  );
}

export default AutoPlay;




const Card = ({name,company,review,img})=>{
  return (
    // <div className='mx-1  md:mx-4 rounded-lg    bg-white  my-4 p-2 md:p-6 shadow-lg flex '>
    //   <div className=" text-center w-[80px] md:w-[200px]  flex justify-center items-center ">
    //     <img src={img} alt=""  className=" w-[50px] h-[50px] md:w-[100px] md:h-[100px] rounded-full "/>
    //   </div>
    //   <div className="w-[300px] md:w-[800px]  flex flex-col justify-around overflow-hidden ">
    //     <p className=' text-[1rem] md:text-[1.5rem]   font-bold text-center'>{name}</p>
    //     <p className='text-[#505050] text-[0.5rem] md:text-base '>{review}</p>
    //   </div>
    // </div>
    <div className="p-4 m-3 rounded-3xl overflow-hidden bg-white montserrat-regular">
      <div className="h-[300px] w-full">
        <img src={img} className="object-cover rounded-2xl w-full h-full bg-center" alt="" />
      </div>
      <h3 className="font-semibold text-2xl mt-1">{name}</h3>
      <h3 className="font-semibold text-xl  my-1 text-gray-400">{company}</h3>
      <p>{review}</p>
    </div>
  )
}

// export default Testimonail;