import React from 'react'
import image1 from "../assets/mentors/image1.png"
import image2 from "../assets/mentors/image2.png"
import image3 from "../assets/mentors/image3.png"
import citrix from "../assets/mentors/citrix.png"
import micro from "../assets/mentors/micro.png"
import oracle from "../assets/mentors/oracle.png"


const mentors = [
  {
    image: image1, // Replace with actual image paths
    name: 'Tushar sain',
    position: 'Software Developer at Microsoft',
    companyLogo: micro,
  },
  {
    image: image2,
    name: 'Ankush Verma',
    position: 'Software Engineer at Citrix',
    companyLogo: citrix,
  },
  {
    image: image3,
    name: 'Bhashkarjya Nath',
    position: 'SDE at Oracle',
    companyLogo: oracle,
  },
];

const Mentors = () => {
  return (

    <div className='my-[100px] '>
       <div className="text-[3rem] md:text-[4rem] text-center font-semibold p-4 montserrat-regular">
        Our Mentors
      </div>

    <div className="flex flex-wrap justify-center">


      {mentors.map((mentor, index) => (
        <MentorCard
          key={index}
          image={mentor.image}
          name={mentor.name}
          position={mentor.position}
          companyLogo={mentor.companyLogo}
        />
      ))}
    </div>
    </div>
  )
}


const MentorCard = ({ image, name, position, companyLogo }) => {
  return (
    // <div className="bg-white shadow-lg shadow-[#dcb6fd] border-[#dcb6fd] border-[2px] w-[300px]   rounded-lg p-6 m-4 flex flex-col items-center">
    //   <img src={image} alt={name} className="w-[10rem] h-[10rem] rounded-full object-cover mb-4" />
    //   <h2 className="text-lg font-semibold mb-2">{name}</h2>
    //   <p className="text-gray-600 mb-2">{position}</p>
    //   <img src={companyLogo} alt={`${name}'s company`} className="w-24 h-16" />
    // </div>
    <div className="w-[350px] p-4 m-3 rounded-3xl overflow-hidden bg-[#ffe8ee]">
      <div className="h-[300px] w-full">
        <img src={image} className="object-cover rounded-2xl w-full h-full bg-center" alt="" />
      </div>
      <h3 className="font-semibold text-2xl mt-1">{name}</h3>
      <h3 className="font-semibold text-xl text-gray-400 my-1">{position}</h3>
      <div>
        <img src={companyLogo} className='w-[30%] object-cover bg-center' alt="" />
      </div>
    </div>
  );
};

export default Mentors;