import React from 'react'

const Termsconditions = () => {
  return (
    <div className=' p-[3rem] '>
        <div className='p-2 text-[2rem] md:text-[4rem] font-bold font-serif '>
            Terms And Conditions
        </div>

        <div className='p-2 md:text-[1rem] font-sans '>
        Please read this Terms of Service Agreement carefully. By using this website or ordering products from this website, you agree to be bound by all of the terms and conditions of this agreement. <br />
        This Terms of Service Agreement (the "Agreement") governs your use of this website, Coursewallah.com (the "Website"), Coursewallah LLP ("Business Name") offer of products for purchase on this Website, or your purchase of products available on this Website. This Agreement includes and incorporates by this reference, the policies and guidelines referenced below. Coursewallah LLP reserves the right to change or revise the terms and conditions of this Agreement at any time by posting any changes or a revised Agreement on this Website. <br />Coursewallah LLP will alert you that changes or revisions have been made by indicating on the top of this Agreement the date it was last revised. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of the Website following the posting of any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. <br />Coursewallah LLP encourages you to review this Agreement whenever you visit the Website to make sure that you understand the terms and conditions governing the use of the Website. This Agreement does not alter in any way the terms or conditions of any other written agreement you may have with Coursewallah LLP for other products or services. 
        <br />If you do not agree to this Agreement (including any referenced policies or guidelines), please immediately terminate your use of the Website. If you would like to print this Agreement, please click the print button on your browser toolbar. <br />

        <br />
        <span className='font-semibold text-[1.1rem]'>I. PRODUCTS</span> <br />
        Terms of Offer. This Website offers for sale certain products (the "Products"). By placing an order for Products through this Website, you agree to the terms set forth in this Agreement.
        <br />
        Customer Solicitation: Unless you notify our third-party call center reps or direct Coursewallah sales reps, while they are calling you, of your desire to opt-out from further direct company communications and solicitations, you are agreeing to continue to receive further emails and call solicitations from Coursewallah and its designated in-house or third-party call team(s).
        Opt-Out Procedure: We provide 2 easy ways to opt-out from future solicitations.

        <ul className=' ml-3 list-disc'>
            <li> You may use the opt-out link found in any email solicitation that you may receive.</li>
            <li> You may also choose to opt-out via sending your email address to: info@coursewallah.com.</li>
        </ul>

        Proprietary Rights: Coursewallah LLP has proprietary rights and trade secrets in the Products. You may not copy, reproduce, resell, or redistribute any Product manufactured and/or distributed by Coursewallah LLP. Coursewallah LLP also has rights to all trademarks and trade dress and specific layouts of this webpage, including calls to action, text placement, images, and other information. <br />

        Sales Tax: If you purchase any Products, you will be responsible for paying any applicable sales tax.

        <br />Refund Policy: <br />

        As mentioned on Coursewallah, we will refund you the complete enrollment amount if you remain unplaced under the following conditions:
        <br />
        <ul className=' ml-3 list-disc'>
            <li> The student should remain unplaced after a year of graduation or 2 years of enrollment with Coursewallah (Whichever is more).</li>
            <li>The student should have at least 75% attendance throughout the live classes.</li>
            <li>The student should have completed the course [including videos, projects, homework, mock interviews, and mock tests].</li>
        </ul>

        If the student doesn't match any of the three conditions, He/She will not be eligible for a full refund.

        <br /><br />
        <span className='font-semibold text-[1.1rem]'>II. WEBSITE</span> <br />

        Content; Intellectual Property; Third-Party Links: In addition to making Products available, this Website also offers information and marketing materials. This Website also offers information, both directly and through indirect links to third-party websites, about educational and career development. Coursewallah does not always create the information offered on this Website; instead, the information is often gathered from other sources. To the extent that Coursewallah does create the content on this Website, such content is protected by intellectual property laws of India, foreign nations, and international bodies. Unauthorized use of the material may violate copyright, trademark, and/or other laws. You acknowledge that your use of the content on this Website is for personal, non-commercial use. Any links to third-party websites are provided solely as a convenience to you. Coursewallah is not responsible for the content of or any damage that may result from your access to or reliance on these third-party websites. If you link to third-party websites, you do so at your own risk.

        <br />
        Use of Website: Coursewallah is not responsible for any damages resulting from the use of this website by anyone. You will not use the Website for illegal purposes. You will:

        <ul className=' ml-3 list-disc'>
            <li>Abide by all applicable local, state, national, and international laws and regulations in your use of the Website (including laws regarding intellectual property).</li>
            <li>Not interfere with or disrupt the use and enjoyment of the Website by other users.</li>
            <li>Not resell material on the Website.</li>
            <li>Not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail, or any other type of unsolicited communication.</li>
            <li>Not defame, harass, abuse, or disrupt other users of the Website.</li>
        </ul>

        <br />
        License: By using this Website, you are granted a limited, non-exclusive, non-transferable right to use the content and materials on the Website in connection with your normal, non-commercial use of the Website. You may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express written authorization from Coursewallah or the applicable third-party (if third-party content is at issue).
        <br />
        Posting: By posting, storing, or transmitting any content on the Website, you hereby grant Coursewallah LLP a perpetual, worldwide, non-exclusive, royalty-free, assignable, right and license to use, copy, display, perform, create derivative works from, distribute, have distributed, transmit and assign such content in any form, in all media now known or hereinafter created, anywhere in the world. Coursewallah does not have the ability to control the nature of the user-generated content offered through the Website. You are solely responsible for your interactions with other users of the Website and any content.

        </div>


    </div>
  )
}

export default Termsconditions;
