const courses = [
    {
      title: 'Web Design from Figma to Webflow',
      instructor: 'Orlando Diggon',
      time: '3h 30min',
      rating: 4.5,
      price: '$124.00',
      imageUrl: 'https://t4.ftcdn.net/jpg/06/33/18/45/360_F_633184577_48ADNiowpMXHf3dJqZgN9P2XBlW1vGFH.jpg',
      url:'web-design'
    },
    {
      title: 'Low Level Design SDE Interview',
      instructor: 'Edward Foster',
      time: '1h 45min',
      rating: 4.6,
      price: '$56.00',
      imageUrl: 'https://t4.ftcdn.net/jpg/06/33/18/45/360_F_633184577_48ADNiowpMXHf3dJqZgN9P2XBlW1vGFH.jpg',
      url:'sde-design'
    },
    {
      title: 'High Level Design SDE Interview',
      instructor: 'Edward Foster',
      time: '1h 45min',
      rating: 4.6,
      price: '$56.00',
      imageUrl: 'https://t4.ftcdn.net/jpg/06/33/18/45/360_F_633184577_48ADNiowpMXHf3dJqZgN9P2XBlW1vGFH.jpg',
      url:'high-sde-design'
    },
    {
      title: 'Machine Learning Fundamentals',
      instructor: 'Edward Foster',
      time: '1h 45min',
      rating: 4.6,
      price: '$56.00',
      imageUrl: 'https://t4.ftcdn.net/jpg/06/33/18/45/360_F_633184577_48ADNiowpMXHf3dJqZgN9P2XBlW1vGFH.jpg',
      url:'ml-fundamentals'
    },
  ];

  export default courses