import React from 'react'
import {IoArrowForward} from 'react-icons/io5'
import { Link } from 'react-router-dom'

const ComingSoon = () => {
  return (
    <>
    <div className='flex flex-col items-center justify-center text-center'>
        <img src="https://react-coming-soon.maksv.me/git.svg" className='w-[60%]' alt="" />
        <h1 className='text-3xl font-semibold my-2'>Coming Soon</h1>
        <p>Mock Interviews Coming Soon! We're working hard to bring you an immersive interview preparation experience. Stay tuned for updates!</p>
        <div className='flex justify-center'>
        <Link to={'/'} className="mb-10 mt-2 hover:bg-purple-700 hover:text-white text-sm text-purple-700 border-[1px] border-gray-600 px-4 py-2 rounded-xl flex justify-evenly items-center"><p className='mx-2'>Go to Home</p><IoArrowForward size={20} fontWeight={200}/></Link>
      </div> 
    </div>
    </>
  )
}

export default ComingSoon