import React from 'react'
import courses from '../../constants/userCourses'
import ourCoursesData from '../../constants/OurCourses'
import doubtsData from '../../constants/doubtsData'
import Doubts from '../Doubts'

const DoubtsHome = () => {
  return (
   <>
   <Doubts courses={courses} ourCourses={ourCoursesData} doubts={doubtsData} />
   </>
  )
}

export default DoubtsHome