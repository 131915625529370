import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import coverImage from "../assets/coverimg.png";
import { Button, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { checkUserExists, sendEmailOtp } from '../services/SignupService';
import bcrypt from 'bcryptjs'
import { hashKey } from '../constants/ApplicationConstants';

const Signup = () => {
  const form = useForm({
    default: {
      fullName: '',
      email: '',
      password: '',
      contact: ''
    }
  })
  const salt = bcrypt.genSaltSync(10)
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  const onFormSubmit = async (data) => {
    data.password = bcrypt.hashSync(data.password, hashKey)
    console.log('form submitted ', data)
    //Enable this later
    const userExists = await checkUserExists(data.email);
    if (userExists.status === 'SUCCESS') {
      sendEmailOtp(data.email, data.fullName);
      navigate("/otpAuth", { state: { 'userSignUpData': data } })
    } else {
      setError(userExists.message)
    }
  }

  const { register, handleSubmit, formState } = form;
  const { errors } = formState

  return (
    <div style={{ marginTop: '1%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginLeft: '12%' }}>
          <div style={{ fontSize: 24, marginBottom: 10 }}>
            Sign-Up and Get Started
          </div>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Stack spacing={1.5}>
              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                {...register('fullName', { required: 'Full Name is Required' })}
                error={!!errors.fullName}
                helperText={errors.fullName?.message} />
              <TextField
                id="outlined-basic"
                label="E-Mail"
                variant="outlined"
                {...register('email', { required: 'Email is Required', pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid E-mail used' } })}
                error={!!errors.email}
                helperText={errors.email?.message} />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                {...register('password', { required: 'Password is Required', minLength: { value: 8, message: 'Minimum 8 letters required' } })}
                error={!!errors.password}
                helperText={errors.password?.message} />
              <TextField
                id="outlined-basic"
                label="Mobile Number"
                variant="outlined"
                {...register('contact', { required: 'Contact is Required' })}
                error={!!errors.contact}
                helperText={errors.contact?.message} />
              <Button
                variant='contained'
                type='submit'
                style={{ backgroundColor: '#cc33ff' }}>
                Sign-Up
              </Button>
              <div style={{color: 'red', fontSize: 14, textAlign: 'center'}}>
                {error}
              </div>
            </Stack>
          </form>
          <hr style={{ marginTop: 20, color: 'grey' }}></hr>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 15, backgroundColor: 'lightgrey', marginTop: 20 }}>
            <p>
              Already have an account? <Link to="/loginPage" style={{ textDecoration: 'underline', color: '#ac00e6', fontWeight: 500 }}> Login</Link>
            </p>
          </div>
        </div>
        <div style={{ width: '50%', marginLeft: '10%' }}>
          <img src={coverImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Signup;