import React from 'react'
import whb from "../assets/whb.png"
import FloatingWhatsappButton from '../components/FloatingWhatsAppButton'
import Chatbot from '../components/chatbot'
import { useEffect } from 'react'
const Aboutus = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <div className=' p-[3rem] '>
        <div className=' md:flex md:justify-center md:h-[300px] '>
          <img src={whb} alt="" className='h-full ' />
        </div>

        <div className='p-2 text-[2rem] md:text-[4rem] font-bold font-serif '>
            About us
        </div>

        <div className='p-2 md:text-[1rem] font-sans '>
        Welcome to Coursewallah, your ultimate destination for unlocking the doors to success! At Coursewallah, we believe that education should be an exhilarating journey filled with discovery, growth, and endless possibilities.
        <br></br>
        Our platform is not just about courses; it's about empowerment. We're here to revolutionize the way you learn, connect, and thrive in today's fast-paced world. With a team of dynamic and passionate educators, Coursewallah offers an immersive learning experience like no other. What sets Coursewallah apart is our commitment to innovation and excellence. Our courses are carefully crafted to blend cutting-edge content with engaging delivery methods, ensuring that you not only grasp the material but also develop a deep understanding that lasts a lifetime.
        <br></br>
        Coursewallah is more than just a learning platform; it's a community. Join a vibrant network of learners from around the globe, where collaboration, creativity, and camaraderie thrive. Our interactive forums, live sessions, and virtual events provide the perfect environment for meaningful connections and shared growth.
        <br></br>
        At Coursewallah, we understand the importance of career advancement. That's why we offer comprehensive career support services, including resume building, interview preparation, and job placement assistance. Our dedicated team is here to guide you every step of the way, helping you turn your dreams into reality.
        <br></br>
        And the best part? Coursewallah offers all this and more at unbeatable prices. We believe that quality education should be accessible to everyone, regardless of background or budget.
        <br></br>
        So, whether you're looking to sharpen your skills, explore new passions, or take your career to new heights, Coursewallah is here to help you unleash your full potential.
        <br></br>
        Join us on this transformative journey and discover what's possible with Coursewallah!

        </div>

		<FloatingWhatsappButton/>
	  	<Chatbot/>
    </div>
  )
}

export default Aboutus;
