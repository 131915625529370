import React, { useState } from 'react';
import { FaBookOpen, FaCheck, FaClock, FaEdit } from 'react-icons/fa';
import axios from 'axios';
import { apiUrl } from '../constants/ApplicationConstants';
import { useAuth } from '../services/AuthProvider';

const Assignments = ({ course,fetchCurrCourse }) => {
    const { currentUser } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newAssignment, setNewAssignment] = useState({ title: '', dueTime: '', marks: '', status: '' });
    const [editingAssignment, setEditingAssignment] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [error, setError] = useState('');

    const handleAddAssignmentClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsEditing(false);
        setEditingAssignment(null);
        setNewAssignment({ title: '', dueTime: '', marks: '', status: '' });
        setError('');
    };

    const handleInputChange = (e, field) => {
        setNewAssignment({
            ...newAssignment,
            [field]: e.target.value,
        });
    };

    const handleAddAssignmentEntry = async (e) => {
        e.preventDefault();
        try {
            console.log({ courseId: course._id, date: selectedDate, assignment: newAssignment })
            const response = await axios.post(
                `${apiUrl}/course/timeline/assignment`,
                { courseId: course._id, date: selectedDate, assignment: newAssignment },
                { withCredentials: true }
            );

            console.log('Assignment added successfully:', response.data);
            handleCloseModal();
            // window.location.reload();
            await fetchCurrCourse();
        } catch (error) {
            console.error('Error adding assignment:', error);
            setError('There was an issue adding the assignment. Please try again.');
        }
    };

    const handleEditAssignmentClick = (assignment, date) => {
        setIsEditing(true);
        setEditingAssignment(assignment);
        setSelectedDate(date);
        setNewAssignment(assignment);
        setIsModalOpen(true);
    };

    const handleEditAssignmentEntry = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${apiUrl}/course/timeline/assignment/edit`,
                { courseId: course._id, assignmentId: editingAssignment._id, date: selectedDate, ...newAssignment },
                { withCredentials: true }
            );

            console.log('Assignment updated successfully:', response.data);
            handleCloseModal();
            // window.location.reload();
            await fetchCurrCourse();
        } catch (error) {
            console.error('Error updating assignment:', error);
            setError('There was an issue updating the assignment. Please try again.');
        }
    };

    return (
        <div className="p-4 font-poppins">
            {/* Render Assignments */}
            {course?.timeline?.length ? (
                course?.timeline?.map((assignmentDay, index) => (
                    assignmentDay.assignments.length !== 0 && <div key={index} className="mb-6">
                        <h3 className="text-gray-500 font-medium text-sm">{assignmentDay.day}</h3>
                        <h2 className="text-2xl font-semibold mb-4">{assignmentDay.date}</h2>
                        {assignmentDay?.assignments?.map((assignment, idx) => (
                            <div key={idx} className="p-4 border border-gray-300 rounded-lg mb-4">
                                <div className='flex items-center justify-between'>
                                    <h4 className="text-xl font-medium my-3">{assignment.title}</h4>
                                    {(currentUser?.role === "Admin" || (currentUser?.role === "Instructor" && course?.instructor === currentUser._id)) && (
                                        <button
                                            onClick={() => handleEditAssignmentClick(assignment, assignmentDay.date)}
                                            className='flex text-sm p-2 px-4 mx-2 items-center bg-[#f0dafc] cursor-pointer hover:bg-[#f0bafc] rounded-xl'>
                                            <FaEdit />
                                            <p className='mx-2'>Edit</p>
                                        </button>
                                    )}
                                </div>
                                <hr />
                                <div className='my-2 flex flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row justify-between items-center'>
                                    <div className="text-gray-600 flex items-center">
                                        {assignment.status === "taken" ? (
                                            <>
                                                <p className='mx-2'>Marks : {assignment.marks}</p>
                                            </>
                                        ) : (
                                            <>
                                                <FaClock />
                                                <p className='mx-3'>Due: {assignment.dueTime}</p>
                                            </>
                                        )}
                                    </div>
                                    <div className='flex text-sm p-2 px-4 mx-2 items-center bg-[#f0dafc] cursor-pointer hover:bg-[#f0bafc] rounded-xl'>
                                        {assignment.status === "taken" ? (
                                            <>
                                                <FaCheck />
                                                <p className='mx-2'>Submitted</p>
                                            </>
                                        ) : (
                                            <>
                                                <FaBookOpen />
                                                <p className='mx-2'>Submit</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))) : (
                <p>No assignments scheduled yet.</p>
            )}

            {/* Button to open modal for adding new assignment */}
            {(currentUser?.role === "Admin" || (currentUser?.role === "Instructor" && course?.instructor === currentUser._id)) && !isEditing && (
                <button
                    onClick={handleAddAssignmentClick}
                    className="mt-4 p-2 bg-purple-400 text-white rounded"
                >
                    Add Assignment
                </button>
            )}

            {/* Modal for Adding or Editing Assignment */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <form
                        onSubmit={isEditing ? handleEditAssignmentEntry : handleAddAssignmentEntry}
                        className="bg-white p-6 rounded-lg h-[90vh] overflow-y-auto"
                    >
                        <h2 className="text-2xl font-semibold mb-4">{isEditing ? 'Edit Assignment' : 'Add New Assignment'}</h2>
                        {error && <p className="text-red-500">{error}</p>}
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                        <input
                            type="date"
                            name="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            required
                            className="w-full p-2 mb-3 border border-gray-300 rounded"
                            disabled={isEditing} // Disable date editing for existing assignments
                        />
                        <h3 className="text-xl font-semibold mb-2">Assignment</h3>
                        <label className="block text-sm my-1 text-gray-700">Assignment Title</label>
                        <input
                            type="text"
                            placeholder="Assignment Title"
                            value={newAssignment.title}
                            onChange={(e) => handleInputChange(e, 'title')}
                            required
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                            maxLength={30}
                        />
                        <label className="block text-sm my-1 text-gray-700">Due Time</label>
                        <input
                            type="time"
                            placeholder="Due Time"
                            value={newAssignment.dueTime}
                            onChange={(e) => handleInputChange(e, 'dueTime')}
                            required
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                        />
                        <label className="block text-sm my-1 text-gray-700">Marks</label>
                        <input
                            type="number"
                            placeholder="Marks"
                            value={newAssignment.marks}
                            onChange={(e) => handleInputChange(e, 'marks')}
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                        />
                        <label className="block text-sm my-1 text-gray-700">Status</label>
                        <select
                            name="status"
                            value={newAssignment.status}
                            onChange={(e) => handleInputChange(e, 'status')}
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="not taken">Pending</option>
                            <option value="taken">Taken</option>
                        </select>
                        <div className="flex justify-end">
                            <button type="submit" className="bg-purple-400 text-white px-4 py-2 rounded mr-2">
                                {isEditing ? 'Update Assignment' : 'Add Assignment'}
                            </button>
                            <button
                                type="button"
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Assignments;
