import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import MyImage from '../assets/logo.png';
import { Link, useNavigation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useAuth } from '../services/AuthProvider';

const Header = () => {
  const { isAuthenticated, logout } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="sm:py-2 shadow-md w-full md:px-0 bg-white text-black sticky top-0 montserrat-regular z-[999]">
      <div className="container mx-auto flex flex-row justify-between items-center  px-2  py-4 md:py-0">
        <div className="flex items-center">
          <div className='h-10 w-12 md:h-14 md:w-16'>
            <Link to="/home" className='h-full my-1'>
              <img src={MyImage} alt="CourseWallah Logo" className='h-full' />
            </Link>
          </div>
          <h1 className="text-[1rem] sm:text-2xl font-semibold">
            COURSEWALLAH
          </h1>
        </div>
        <nav className={`flex-grow md:flex items-center justify-end hidden `}>
          <ul className="mx-3 flex flex-col md:flex-row items-center text-[0.8rem] md:text-[1.1rem] sm:text-base justify-between gap-[2px] sm:gap-2 space-x-4 md:space-x-4">
            <li>
              <Link to="/home" className="hover:underline">
                Home
              </Link>
            </li>
            <p className="opacity-50">|</p>
            <li>
              <Link to="/aboutus" className="hover:underline">
                About us
              </Link>
            </li>
            <p className="opacity-50">|</p>
            <li>
              <Link to="/contactus" className="hover:underline">
                Contact Us
              </Link>
            </li>
            {/* <li className='px-10 text-[25px] hidden md:block'>
              <a href="http://facebook.com/coursewallah">
                <FontAwesomeIcon className='mx-2 hover:text-[#8f46de] transition ease-in-out duration-200' icon={faFacebook} />
              </a>
              <a href="https://www.instagram.com/iamcoursewallah/">
                <FontAwesomeIcon className='mx-2 hover:text-[#8f46de] transition ease-in-out duration-200' icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com/company/coursewallah/about/">
                <FontAwesomeIcon className='mx-2 hover:text-[#8f46de] transition ease-in-out duration-200' icon={faLinkedin} />
              </a>
            </li> */}
          </ul>

          {isAuthenticated ? <div className='ml-10'>
            <button onClick={logout} className="rounded-tl-[15px] rounded-br-[15px] bg-[#8f46de] hover:bg-[#7524cc] text-white p-2 px-3 mx-3">
              LOGOUT
              <FontAwesomeIcon className="ml-4 transform hover:translate-x-2 transition duration-300" icon={faAngleRight} />
            </button>
          </div> : <div className='ml-10'>
            <Link to="/loginPage">
              {/* <Button variant="outlined" style={{ color: 'white', borderColor: 'white', marginLeft: 5 }}>Login</Button> */}
              <button className="rounded-tl-[15px] rounded-br-[15px] bg-[#8f46de] hover:bg-[#7524cc] text-white p-2 px-3 mx-3">
                LOGIN
                <FontAwesomeIcon className="ml-4 transform hover:translate-x-2 transition duration-300" icon={faAngleRight} />
              </button>
            </Link>
            <Link to="/signup">
              <button className="rounded-tl-[15px] rounded-br-[15px] bg-[#8f46de] hover:bg-[#7524cc] text-white p-2 px-3 mx-3">
                SIGN-UP
                <FontAwesomeIcon className="ml-4 transform hover:translate-x-2 transition duration-300" icon={faAngleRight} />
              </button>
              {/* <Button variant="outlined" style={{ color: 'white', borderColor: 'white', marginLeft: 5 }}>Sign-up</Button> */}
            </Link>
          </div>}
        </nav>
        <button className="md:hidden text-xl" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </button>
      </div>
      {/* Sliding menu for mobile */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-md ">
          <ul className="flex flex-col items-center text-[1rem] sm:text-[1.2rem] space-y-2 py-4">
            <li>
              <Link to="/home" className="hover:underline" onClick={toggleMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/aboutus" className="hover:underline" onClick={toggleMenu}>
                About us
              </Link>
            </li>
            <li>
              <Link to="/contactus" className="hover:underline" onClick={toggleMenu}>
                Contact Us
              </Link>
            </li>
            <li className='text-[25px]'>
              <a href="http://facebook.com/coursewallah">
                <FontAwesomeIcon className='mx-2 hover:text-[#8f46de] transition ease-in-out duration-200' icon={faFacebook} />
              </a>
              <a href="https://www.instagram.com/iamcoursewallah/">
                <FontAwesomeIcon className='mx-2 hover:text-[#8f46de] transition ease-in-out duration-200' icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com/company/coursewallah/about/">
                <FontAwesomeIcon className='mx-2 hover:text-[#8f46de] transition ease-in-out duration-200' icon={faLinkedin} />
              </a>
            </li>
            <Button variant="outlined" style={{ color: 'black', borderColor: 'black' }}>Login</Button>
            <Button variant="outlined" style={{ color: 'black', borderColor: 'black' }}>Sign-up</Button>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
