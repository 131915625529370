import React, { useEffect, useState } from 'react'
import CourseTimeline from '../CourseTimeline'
import ourCoursesData from '../../constants/OurCourses'
import axios from 'axios';
import { apiUrl } from '../../constants/ApplicationConstants';
import {useParams} from "react-router-dom"

const CourseTimelineHome = () => {
    const {id} = useParams();
    
    const [currCourse, setCurrCourse] = useState([])
    const fetchCurrCourse = async () => {
        try {
            const response = await axios.post(`${apiUrl}/course/getCourseById`,{courseId:id},{withCredentials:true});
            const data = response.data;
            console.log(data);
            setCurrCourse(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    }
    useEffect(() => {
        fetchCurrCourse();
    }, [])

    return (
        <>
            <h2 className='m-3 text-2xl font-medium'>{currCourse.title}</h2>
            <CourseTimeline course={currCourse}  fetchCurrCourse={fetchCurrCourse}/>
        </>
    )
}

export default CourseTimelineHome