import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import myimage1 from "../assets/courseimg.png";
import { useContext } from "react";
import { ScrollContext } from '../App';
import Typewriter from 'typewriter-effect';
import { FiTarget } from "react-icons/fi";

const steps = [
  "Enroll with us.",
  "Learn coding from the best mentors.",
  "Make amazing projects.",
  "Get Placed.",
];

const Course = () => {
  const { scrollToSection } = useContext(ScrollContext);


  return (
    <div className=' flex flex-col md:flex-row my-[100px]'>
      <div className='flex-1 flex items-end '>
        <img src={myimage1} alt="" />
      </div>
      <div className='py-4 md:py-0 flex-1 flex flex-col justify-around px-10'>
        <p className='montserrat-regular sm:leading-[60px] sm:text-[50px] leading-[40px] font-bold text-[30px] md:leading-[60px] md:text-[50px]'>Want to get placed as a <span className='font-semibold text-[#8f46de]'>
          <Typewriter
            options={{
              strings: ['Software Engineer ?','Web Developer ?','Data Scientist ?'],
              autoStart: true,
              loop: true,
              cursorClassName:'text-black'
            }}
          />
        </span></p>


        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
            <div className="flex items-center relative">
              <div className="w-3 h-3 md:w-6 md:h-6 bg-white border-4 border-[#8f46de] rounded-full flex items-center justify-center">
                <div className=" w-1 h-1 md:w-2 md:h-2 bg-[#8f46de] rounded-full"></div>
              </div>
              {/* <FiTarget color='#8f46de' size={30}/> */}
              <span className="ml-4 text-2xl montserrat-regular">{step}</span>
            </div>
          </div>
        ))}

        <button onClick={scrollToSection} className="w-[200px] rounded-tl-[15px] rounded-br-[15px] mb-20 mt-6 text-[0.7rem] md:text-[1.2rem] bg-[#8f46de] text-white py-3 montserrat-regular">LEARN NOW
          <FontAwesomeIcon className="ml-4 transform hover:translate-x-2 transition duration-300" icon={faAngleRight} />
        </button>
      </div>
    </div>
  )
}

export default Course;
