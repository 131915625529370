import React, { useEffect } from 'react';
import courseimage from "../assets/dsa.png";
import Faq from '../components/Faq';
import Mentors from '../components/Mentors';
import { Link, useNavigate } from 'react-router-dom';
import FloatingWhatsappButton from './../components/FloatingWhatsAppButton';
import Chatbot from '../components/chatbot';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';

const Coursepage = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const amount = 8999;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const goToCheckoutPage = () => {
    navigate('/checkout', { state: { name: 'Data Structures And Algorithms', amount: amount, img: courseimage } });
  };

  return (
    <div className='course-page'>
      <div className='course-content flex flex-col md:flex-row my-16'>
        <div className='course-image-container flex-1'>
          <div className='mx-16 my-8'>
            <img src={courseimage} alt="Course" className='w-full rounded-md' />
          </div>
        </div>
        <div className='course-info flex-1'>
          <div className='h-full px-16 py-8 flex flex-col justify-end'>
            <h1 className='text-3xl font-semibold mb-12'>Data Structures And Algorithms</h1>
            <p>Are you looking for a comprehensive course that will help you get placed in your dream company like Amazon, Google, etc.? This course is taught by expert mentors who will ensure you acquire all the skills needed to crack top companies. It also includes assured interview calls, mock interviews, and more.</p>
            <div className='flex justify-between p-4 mt-8'>
              <div className='text-xl font-semibold'>
                ₹ {amount.toFixed(2)} <span className='text-lg line-through opacity-50'>₹ 14999.00</span>
              </div>
              <button onClick={goToCheckoutPage} className='buy-now-button hover:bg-[#df1f50] hover:text-white px-8 py-2 rounded-tr-lg rounded-bl-lg border-2 border-[#df1f50] text-lg'>
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='course-overview bg-[#ffe8ee] pb-8'>
        <h2 className='text-center text-3xl font-bold pt-8'>Course Overview</h2>

        <div className='overview-grid hidden md:grid mx-24 grid-cols-3 gap-3 mt-4'>
          <div className="tile shadow-lg bg-white border-[#dcb6fd] border-4 rounded-lg">
            <div className='ml-4'>
              <p className="mt-4 text-sm">Day 1-15</p>
              <h3 className='text-xl font-sans'>Module 1</h3>
            </div>
            <ul className="mt-4 px-8 pl-12 pb-4 list-disc">
              <li>IDE: Installation and Setup</li>
              <li>Variable, Data Types and Pointers</li>
              <li>Operators</li>
              <li>Conditional Flows</li>
              <li>Arrays, Objects, Functions and Classes</li>
              <li>OOPs, STL/Collections</li>
            </ul>
          </div>

          <div className="tile shadow-lg bg-white border-[#dcb6fd] border-4 rounded-lg">
            <div className='ml-4'>
              <p className="mt-4 text-sm">Day 16-30</p>
              <h3 className='text-xl font-sans'>Module 2</h3>
            </div>
            <ul className="mt-4 px-8 pl-12 pb-4 list-disc">
              <li>Time and space complexity analysis</li>
              <li>Basic Math for Coding</li>
              <li>1D arrays</li>
              <li>Techniques on Arrays: 2 Pointer, Sliding Window, Prefix sum, Hashing</li>
              <li>Bit manipulation, Binary search</li>
              <li>Recursion and Backtracking</li>
            </ul>
          </div>

          <div className="tile shadow-lg bg-white border-[#dcb6fd] border-4 rounded-lg">
            <div className='ml-4'>
              <p className="mt-4 text-sm">Day 31-45</p>
              <h3 className='text-xl font-sans'>Module 3</h3>
            </div>
            <ul className="mt-4 px-8 pl-12 pb-4 list-disc">
              <li>Sorting Techniques</li>
              <li>2D Arrays Traversal and Techniques</li>
              <li>Linked List</li>
              <li>Stacks & Queues</li>
            </ul>
          </div>

          <div className="tile shadow-lg bg-white border-[#dcb6fd] border-4 rounded-lg">
            <div className='ml-4'>
              <p className="mt-4 text-sm">Day 46-60</p>
              <h3 className='text-xl font-sans'>Module 4</h3>
            </div>
            <ul className="mt-4 px-8 pl-12 pb-4 list-disc">
              <li>Trees: Introduction, Binary Trees, and BST</li>
              <li>Heaps, Tries</li>
              <li>Graphs: Introduction, Representation, Algorithms</li>
              <li>Greedy Techniques, Dynamic Programming (DP)</li>
              <li>String Matching Algorithms: KMP, Rolling Hash</li>
            </ul>
          </div>
        </div>

        {isMobile && (
          <div className='mobile-overview mt-4 px-8'>
            <Slider {...settings}>
              <div className="tile  bg-white border-[#dcb6fd] border-4 rounded-lg">
                <div className='ml-4'>
                  <p className="mt-4 text-sm">Day 1-15</p>
                  <h3 className='text-xl font-sans'>Module 1</h3>
                </div>
                <ul className="mt-4 text-sm md:text-base px-8 pl-12 pb-4 list-disc">
                  <li>IDE: Installation and Setup</li>
                  <li>Variable, Data Types and Pointers</li>
                  <li>Operators</li>
                  <li>Conditional Flows</li>
                  <li>Arrays, Objects, Functions and Classes</li>
                  <li>OOPs, STL/Collections</li>
                </ul>
              </div>

              <div className="tile  bg-white border-[#dcb6fd] border-4 rounded-lg">
                <div className='ml-4'>
                  <p className="mt-4 text-sm">Day 16-30</p>
                  <h3 className='text-xl font-sans'>Module 2</h3>
                </div>
                <ul className="mt-4 text-sm md:text-base px-8 pl-12 pb-4 list-disc">
                  <li>Time and space complexity analysis</li>
                  <li>Basic Math for Coding</li>
                  <li>1D arrays</li>
                  <li>Techniques on Arrays: 2 Pointer, Sliding Window, Prefix sum, Hashing</li>
                  <li>Bit manipulation, Binary search</li>
                  <li>Recursion and Backtracking</li>
                </ul>
              </div>

              <div className="tile  bg-white border-[#dcb6fd] border-4 rounded-lg">
                <div className='ml-4'>
                  <p className="mt-4 text-sm">Day 31-45</p>
                  <h3 className='text-xl font-sans'>Module 3</h3>
                </div>
                <ul className="text-sm md:text-base mt-4 px-8 pl-12 pb-4 list-disc">
                  <li>Sorting Techniques</li>
                  <li>2D Arrays Traversal and Techniques</li>
                  <li>Linked List</li>
                  <li>Stacks & Queues</li>
                </ul>
              </div>

              <div className="tile bg-white border-[#dcb6fd] border-4 rounded-lg">
                <div className='ml-4'>
                  <p className="mt-4 text-sm">Day 46-60</p>
                  <h3 className='text-xl font-sans'>Module 4</h3>
                </div>
                <ul className="text-sm md:text-base mt-4 px-8 pl-12 pb-4 list-disc">
                  <li>Trees: Introduction, Binary Trees, and BST</li>
                  <li>Heaps, Tries</li>
                  <li>Graphs: Introduction, Representation, Algorithms</li>
                  <li>Greedy Techniques, Dynamic Programming (DP)</li>
                  <li>String Matching Algorithms: KMP, Rolling Hash</li>
                </ul>
              </div>
            </Slider>
          </div>
        )}
      </div>

      <Mentors />
      <Faq />
      <FloatingWhatsappButton />
      <Chatbot />
    </div>
  );
};

export default Coursepage;
