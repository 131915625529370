import axios from "axios"
import { apiUrl } from "../constants/ApplicationConstants"

export const sendEmailOtp = async (email, fullName) => {
    const postParams = { 'email': email, 'fullName': fullName };
    const result = await axios.post(apiUrl + '/sendEmailOtp', postParams);
    return result.data;
}

export const validateOtp = async (email, otp) => {
    const postParams = { 'email': email, 'otp': otp };
    const result = await axios.post(apiUrl + '/validateOtp', postParams);
    return result.data;
}

export const registerUser = async (userData) => {
    const postParams = userData;
    const result = await axios.post(apiUrl + '/register', postParams);
    return result.data;
}

export const checkUserExists = async (email) => {
    const postParams = {'email': email};
    const result = await axios.post(apiUrl + '/checkUserExists', postParams);
    return result.data;
}