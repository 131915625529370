import { Button } from '@mui/material';
import React, { useState } from 'react';
import coverImage from "../assets/coverimg.png";
import OtpInput from "../components/OtpInput"
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { registerUser, sendEmailOtp, validateOtp } from '../services/SignupService';
import { Otptimer } from "otp-timer-ts";
const OtpAuthenticate = () => {
    const locData = useLocation()
    const [otp, setOtp] = useState('')
    const [validationMsg, setValidationMsg] = useState(null);
    const [otpStatus, setOtpStatus] = useState("")
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(10)
    const navigate = useNavigate();

    const userSignUpData = locData.state?.userSignUpData
    const onOtpSubmit = (otpEntered) => {
        setOtp(otpEntered)
    }

    const handleSubmit = async () => {
        const resp = await validateOtp(userSignUpData.email, otp);
        console.log("Resp", resp)
        setValidationMsg(resp.message);
        setOtpStatus(resp.status)
        if (resp.status == "SUCCESS") {
            registerUser(userSignUpData)
            navigate("/dashboard")
        }
    }

    const handleResend = () => {
        setMinutes(1)
        setSeconds(30)
        sendEmailOtp(userSignUpData.email, userSignUpData.fullName)
        setOtpStatus('SUCCESS')
        setValidationMsg('OTP resent to ' + userSignUpData.email);
    }

    return (
        <div style={{ marginTop: '1%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginLeft: '12%', marginTop: '-10%' }}>
                    <div style={{ fontSize: 24, marginBottom: 10 }}>
                        Enter OTP sent on {userSignUpData.email}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                        <OtpInput length={6} onOtpSubmit={onOtpSubmit} />
                    </div>
                    <Button
                        variant='contained'
                        onClick={() => { handleSubmit() }}
                        style={{ backgroundColor: '#cc33ff', marginTop: 20 }}>
                        Submit OTP
                    </Button>
                    <div style={{ textAlign: 'center', marginTop: 5 }}>
                        <Otptimer
                            minutes={minutes}
                            seconds={seconds}
                            onResend={handleResend}
                            text='Resend OTP in'
                            buttonStyle={{ color: '#cc33ff', fontWeight: 500, textDecoration: 'underline' }}
                            timerStyle={{ color: 'red' }} />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 10, color: otpStatus == 'SUCCESS' ? 'green' : 'red' }}>
                        {validationMsg}
                    </div>
                </div>
                <div style={{ width: '50%', marginLeft: '10%' }}>
                    <img src={coverImage} alt="" />
                </div>
            </div>
        </div>
    )
}

export default OtpAuthenticate