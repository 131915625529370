import React, { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaUser, FaChartBar, FaUserFriends } from 'react-icons/fa';
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import { IoArrowForward } from "react-icons/io5";
import { IoChatbubblesOutline } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import MyImage from '../assets/logo.png';
import { useAuth } from '../services/AuthProvider';
import Modal from 'react-modal';
import { apiUrl } from '../constants/ApplicationConstants';
import axios from 'axios';
import avatar from '../assets/pp.jpg'
import { validateContact, validateUsername } from '../services/validators';

function Sidebar() {
  const { currentUser, logout,getCurrentUser } = useAuth();
  const location = useLocation();
  const activePath = location.pathname;
  const [showMenu, setShowMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling modal visibility
  const [formData, setFormData] = useState({
    userName: currentUser.userName,
    contact: currentUser.contact,
    profilePhoto: currentUser.profilePhoto
  });
  const [postImage, setPostImage] = useState({ myFile: "" })
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file && file.size > maxSize) {
      alert("File Size Exceeds 2MB, please upload smaller image")
      e.target.value = '';
      return;
    }
    const base64 = await convertToBase64(file);
    console.log(base64)
    setPostImage({ ...postImage, myFile: base64 })
    setFormData({ ...formData, profilePhoto: base64 });
  }

  const menuItems = [
    { id: 'dashboard', label: 'Dashboard', icon: MdOutlineSpaceDashboard, path: '/dashboard' },
    { id: 'myCourses', label: 'My Courses', icon: FaUser, path: '/dashboard/courses' },
    { id: 'assignments', label: 'Assignments', icon: FaChartBar, path: '/dashboard/assignments' },
    { id: 'referrals', label: 'Referrals', icon: FaUserFriends, path: '/dashboard/referrals' },
    { id: 'doubts', label: 'Doubts', icon: RiQuestionnaireFill, path: '/dashboard/doubts' },
    { id: 'mockInterviews', label: 'Mock Interviews', icon: IoChatbubblesOutline, path: '/dashboard/mockInterviews' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateUsername(formData.userName)) {
      alert("Invalid username: should be alphanumeric and max 20 characters.");
      return;
    }
    if (!validateContact(formData.contact)) {
      alert("Invalid contact number: should be 10 digits.");
      return;
    }
    try {
      let data;
      if (formData.profilePhoto) {
        data = { userName: formData.userName, contact: formData.contact, profilePhoto: formData.profilePhoto }
      }
      else {
        data = { userName: formData.userName, contact: formData.contact }
      }
      const response = await axios.patch(`${apiUrl}/user/${currentUser.userId}`, data, { withCredentials: true });
      const result = await response.data;
      console.log('Profile updated successfully:', result);
      setIsModalOpen(false);
      // window.location.reload();
      await getCurrentUser();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };
  return (
    <div className="bg-gray-900 text-white py-6 sticky h-screen">
      <div className="relative flex w-full justify-center items-center mb-0 md:mb-0 lg:mb-5 xl:mb-5 2xl:mb-5 mx-1">
        <Link to={'/'} className='h-10 w-10'>
          <img src={MyImage} alt="CourseWallah Logo" className='h-full' />
        </Link>
        <Link to={'/'}>
        <h1 className="text-xl font-bold poppins-regular mx-2">
          COURSEWALLAH
        </h1>
        </Link>
        <div className='absolute right-10 flex md:flex lg:hidden xl:hidden 2xl:hidden' onClick={() => { setShowMenu(!showMenu) }}>
          <GiHamburgerMenu size={20} color='gray' />
        </div>
      </div>
      <div className="border-y-[1px] flex items-center justify-evenly py-3 border-purple-300 mx-1 hidden md:hidden lg:flex xl:flex 2xl:flex">
        <div className='' onClick={() => setIsModalOpen(true)}>
          {currentUser?.profilePhoto ? <img className='h-[50px] w-[50px] rounded-full cursor-pointer' src={`${currentUser?.profilePhoto}`} alt="" /> :
            <img className='h-[50px] w-[50px] rounded-full cursor-pointer' src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=2123" alt="" />}
        </div>
        <div onClick={() => setIsModalOpen(true)} className='ml-1 text-sm flex flex-col cursor-pointer justify-center '>
          <h3 className='font-medium'>{currentUser.userName}</h3>
          <p className='text-gray-300 font-light'>{currentUser.role}</p>
        </div>
      </div>
      <div className={`${showMenu ? 'h-auto' : 'h-0'} transition transition-300 lg:h-auto xl:h-auto 2xl:h-auto overflow-hidden`}>
        <nav className='my-10'>
          <ul>
            {menuItems.map((item) => (
              <li
                key={item.id}
                onClick={() => { setShowMenu(!showMenu) }}
                className={`mx-4 mb-1 p-2 rounded-2xl px-4 flex items-center cursor-pointer ${activePath === item.path ? 'bg-purple-400 text-white' : 'text-gray-400 hover:bg-purple-400/10'
                  }`}
              >
                <Link to={item.path} className="flex items-center w-full">
                  <item.icon className="inline-block mr-2" />
                  <p className='font-light'>{item.label}</p>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div className='flex justify-evenly'>
          <button className="flex md:flex lg:hidden xl:hidden 2xl:hidden text-sm text-purple-300 border-[1px] border-gray-600 px-4 py-2 rounded-xl flex justify-evenly items-center">
            <p className='mx-2'>Profile</p><IoArrowForward size={16} fontWeight={200} />
          </button>
          <button onClick={logout} className=" text-sm text-purple-300 border-[1px] border-gray-600 px-4 py-2 rounded-xl flex justify-evenly items-center">
            <p className='mx-2'>Log Out</p><IoArrowForward size={16} fontWeight={200} />
          </button>
        </div>
      </div>

      {/* Modal for updating user profile */}
      {/* <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} className="flex justify-center items-center"> */}
      {/* {isModalOpen && ( */}
      <Modal isOpen={isModalOpen} ariaHideApp={false} className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg  text-black h-fit  overflow-y-auto">
          <h2 className="text-2xl font-semibold mb-4">Update Profile</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="userName" className="block text-sm font-medium">Username</label>
              <input
                type="text"
                id="userName"
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
                required
                maxLength={30}
                className="border rounded-lg w-full p-2 mt-1"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="contact" className="block text-sm font-medium">Contact Number</label>
              <input
                type="phone"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                required
                minLength={10}
                maxLength={10}
                className="border rounded-lg w-full p-2 mt-1"
              />
            </div>
            {/* <div className="mb-4">
              <label htmlFor="profilePhoto" className="block text-sm font-medium">Profile Photo</label>
              <input
                type="file"
                id="profilePhoto"
                name="profilePhoto"
                accept="image/*"
                onChange={handlePhotoChange}
                className="border rounded-lg w-full p-2 mt-1"
              />
            </div> */}
            <div className="mb-4">
              {/* <label htmlFor="profilePhoto" className="block text-sm font-medium">Profile Photo</label>
              <input
                type="text"
                id="profilePhoto"
                name="profilePhoto"
                value={formData.profilePhoto}
                onChange={handleInputChange}
                className="border rounded-lg w-full p-2 mt-1"
              /> */}
              <h6 className="block text-sm font-medium">Profile Photo</h6>
              <label htmlFor="file-upload" className='custom-file-upload'>
                {/* <img width={150} src={currentUser?.profilePhoto || avatar} alt="" /> */}
                <img width={150} src={postImage?.myFile || currentUser?.profilePhoto} alt="" />
              </label>

              <input
                type="file"
                lable="Image"
                name="myFile"
                id='file-upload'
                accept='.jpeg, .png, .jpg'
                onChange={(e) => handleFileUpload(e)}
              />
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={() => {setPostImage({ myFile: "" });setIsModalOpen(false)}} className="px-4 py-2 bg-gray-200 rounded-lg mr-2 hover:bg-gray-300">Cancel</button>
              <button type="submit"  className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600">Save</button>
            </div>
          </form>
        </div>
      </Modal>
      {/* )} */}
    </div>
  );
}

export default Sidebar;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result)
    };
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}