import React from 'react'

const ReturnAndRefund = () => {
  return (
    <div className='pb-[400px] p-[3rem] '>
        <div className='p-2 text-[2rem] md:text-[4rem] font-bold font-serif '>
	  Return and Refund
        </div>

        <div className='p-2 md:text-[1rem] font-sans '>
        <br /><br />

        As mentioned on Coursewallah, we will refund you the complete enrollment amount if you remain unplaced under the following conditions:
        <br />
        <ul className=' ml-3 list-disc'>
            <li> The student should remain unplaced after a year of graduation or 2 years of enrollment with Coursewallah (Whichever is more).</li>
            <li>The student should have at least 75% attendance throughout the live classes.</li>
            <li>The student should have completed the course [including videos, projects, homework, mock interviews, and mock tests].</li>
        </ul>

        If the student doesn't match any of the three conditions, He/She will not be eligible for a full refund.

        <br /><br />
    </div>
	  </div>
  )
}

export default ReturnAndRefund;
