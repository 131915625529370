import './App.css';
import Footer from './components/Footer';
import Coursepage from './pages/Coursepage';
import Contactus from './pages/Contactus';
import { createContext, useRef } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import Aboutus from './pages/Aboutus';
import Termsconditions from './pages/Termsconditions';
import Privacy from './pages/Privacy';
import Checkout from './pages/Checkout';
import ReturnAndRefund from './pages/ReturnandRefundPolicy';
import Login from './pages/Login';
import Signup from './pages/Signup';
import OtpAuthenticate from './pages/OtpAuthenticate';
import Dashboard from './pages/Dashboard';
import Header from './components/Header';
import Home from './pages/Home';
import { AuthProvider } from './services/AuthProvider';
import DoubtsHome from './components/DashboardItems/DoubtsHome';
import ReferralsHome from './components/DashboardItems/ReferralsHome';
import AssignmentsHome from './components/DashboardItems/AssignmentsHome';
import MyCoursesHome from './components/DashboardItems/MyCoursesHome';
import MockInterviewsHome from './components/DashboardItems/MockInterviewsHome';
import DashboardHome from './components/DashboardItems/DashboardHome';
import CourseTimelineHome from './components/DashboardItems/CourseTimelineHome';
import EnrolledCourses from './components/EnrolledCourses';
import ProtectedRoute from './components/ProtectedRoute'
import AssignmentsTimelineHome from './components/DashboardItems/AssignmentsTimelineHome';

const ScrollContext = createContext();

function App() {
  return (
    <Router>
      <AuthProvider>
        <ScrollProvider>
          <ConditionalHeader />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/course1" element={<Coursepage />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/termsandconditions" element={<Termsconditions />} />
            <Route path="/privacypolicy" element={<Privacy />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/returnandrefund" element={<ReturnAndRefund />} />
            <Route path="/loginPage" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/otpAuth" element={<OtpAuthenticate />} />
            <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
              <Route index element={<DashboardHome />} />
              <Route path="courses" element={<MyCoursesHome />} >
                <Route index element={<EnrolledCourses CourseAssign={"courses"} />} />
                <Route path=":id" element={<CourseTimelineHome />} />
              </Route>
              <Route path="assignments" element={<AssignmentsHome />} >
                <Route index element={<EnrolledCourses CourseAssign={"assignments"} />} />
                <Route path=':id' element={<AssignmentsTimelineHome />} />
              </Route>
              <Route path="referrals" element={<ReferralsHome />} />
              <Route path="doubts" element={<DoubtsHome />} />
              <Route path="mockInterviews" element={<MockInterviewsHome />} />
            </Route>
          </Routes>
          <ConditionalFooter />
        </ScrollProvider>
      </AuthProvider>
    </Router>
  );
}

const ScrollProvider = ({ children }) => {
  const sectionRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ScrollContext.Provider value={{ sectionRef, scrollToSection }}>
      {children}
    </ScrollContext.Provider>
  );
};

const ConditionalHeader = () => {
  const location = useLocation();

  // Define the routes where the Header should be shown
  const routesWithHeader = [
    '/',
    '/home',
    '/course1',
    '/contactus',
    '/aboutus',
    '/termsandconditions',
    '/privacypolicy',
    '/checkout',
    '/returnandrefund',
    '/loginPage',
    '/signup',
    '/otpAuth',
  ];

  return routesWithHeader.includes(location.pathname) ? <Header /> : null;
};
const ConditionalFooter = () => {
  const location = useLocation();

  // Define the routes where the Footer should be shown
  const routesWithFooter = [
    '/',
    '/home',
    '/course1',
    '/contactus',
    '/aboutus',
    '/termsandconditions',
    '/privacypolicy',
    '/checkout',
    '/returnandrefund',
    '/loginPage',
    '/signup',
    '/otpAuth',
  ];

  return routesWithFooter.includes(location.pathname) ? <Footer /> : null;
};

export { ScrollContext, ScrollProvider };
export default App;
