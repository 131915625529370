import React from 'react';
import '../whatsapp.css';
import whatsapp from '../assets/whatsapp.svg'
const FloatingWhatsAppButton = () => {
  const handleClick = () => {
    window.open('https://wa.me/+918319883507', '_blank');
  };

  return (
    <div className="floating-whatsapp-button" onClick={handleClick}>
      <img src={whatsapp} alt="WhatsApp" />
      <span>Get in Touch</span>
    </div>
  );
};

export default FloatingWhatsAppButton;
