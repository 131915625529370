import Cover from "../components/Cover"
import Coursesoverview from "../components/Coursesoverview"
import Course from "../components/Course"
import Testimonial from "../components/Testimonial"
import Allcourses from "../components/Allcourses"
import Mentors from "../components/Mentors"
import Faq from "../components/Faq"
import FloatingWhatsAppButton from "../components/FloatingWhatsAppButton"
import Chatbot from "../components/chatbot"
import Contactus from "./Contactus"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAuth } from "../services/AuthProvider"
import axios from "axios"

const Home = () => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const checkUserLoggedIn = async () => {
            try {
                const response = await axios.post('/checkIfuserloggedIn');
                auth.setIsAuthenticated(true);
                auth.setCurrentUser(response.data);
            } catch (error) {
                auth.setIsAuthenticated(false);
                auth.setCurrentUser(null);
            } finally {
                setIsLoading(false);
            }
        };

        checkUserLoggedIn();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>
    }
    return (
        <div>
            <Cover></Cover>
            <Coursesoverview></Coursesoverview>
            <Course></Course>
            <Testimonial></Testimonial>
            <Allcourses></Allcourses>
            <Mentors></Mentors>
            <Faq></Faq>
            <Contactus></Contactus>
            <FloatingWhatsAppButton />
            <Chatbot />
        </div>
    )
}

export default Home