import React from 'react'
import MockInterviews from '../MockInterviews'

const MockInterviewsHome = () => {
  return (
    <>
    <MockInterviews/>
    </>
  )
}

export default MockInterviewsHome