import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const questions = [
  {
    id: 1,
    question: 'What will the course structure be like?',
    answer: 'The course will consist of weekly 5 live classes for each topic, according to the course that you have enrolled in. During these classes, mentors will provide detailed instruction, and you will also have the opportunity to work on real-time projects. In addition, you will have access to self-paced content, homework assignments, mock interviews, and mock tests.',
  },
  {
    id: 2,
    question: 'How can I ask my doubts during the course?',
    answer: 'You can ask your doubts during the live classes, and you will also be added to a group where you can ask any questions you may have.',
  },
  {
    id: 3,
    question: 'How will I receive placement assistance?',
    answer: 'We will have a dedicated HR team that will connect recruiters from various companies to Coursewallah. Additionally, upon completing the course, we will refer you to good software companies based on your mock interview performance. You will also receive regular job notifications, access to the best resume templates, interview and mock test opportunities. Most importantly, the skills you will acquire during the boot camp will be essential in successfully cracking interviews with major companies.    ',
  },
  {
    id: 4,
    question: 'Tell me more about the "Full Refund if Unplaced" policy.',
    answer: 'As mentioned, students are eligible for a full refund if they do not secure a placement even after completing the course and scheduling interviews through our referrals. For more details, you can refer to our Terms and Conditions Page.',
  },
  {
    id: 5,
    question: 'What will be the duration of the course?',
    answer: 'The placement preparation program consists of 4 to 6 months of training, 5 live classes per week, self-paced content, and projects.',
  },
  {
    id: 6,
    question: 'I am from a non-tech background. Am I eligible?',
    answer: 'Yes, Coursewallah boot camp is curated so that even if you come from a non-tech background, you will learn everything from the basics to advanced topics with equal placement opportunities. The real-time projects you build here will compensate for your lack of a degree.',
  },
];



const Faq = () => {

  const [activeQuestion, setActiveQuestion] = useState(null);


  const handleQuestionClick = (id) => {
    setActiveQuestion(activeQuestion === id ? null : id);
  };


  return (
    <div className=' bg-[#ffe8ee] p-2  py-[50px]' >
      <div className='text-center montserrat-regular font-semibold text-[2rem] md:text-[3rem] my-2 md:my-4'>
        FAQ
      </div>
      <div className='flex flex-col gap-2 mx-[1rem] md:mx-[3rem] md:grid md:grid-cols-2 montserrat-regular'>
        {questions.map((question) => (
          <div key={question.id} className='cursor-pointer   '>
            <div className=' bg-white p-4 border-[2px] rounded-2xl  border-[#dcb6fd]'>
              <div onClick={() => handleQuestionClick(question.id)} className='flex items-center justify-between'>
                <p className='text-lg font-semibold '>{question.question}</p>
                {activeQuestion === question.id ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
              </div>
              <p className={`text-[0.7rem] transition transition-all duration-300 overflow-hidden md:text-base ${activeQuestion === question.id ? "max-h-[500px]" : "max-h-0"}`}>{question.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq;
