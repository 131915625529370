import React, { useEffect, useState } from 'react';
import { FaClock, FaEdit } from 'react-icons/fa';
import { MdNotes } from 'react-icons/md';
import { SiGooglemeet } from "react-icons/si";
import axios from 'axios';
import { apiUrl } from '../constants/ApplicationConstants';
import { useAuth } from '../services/AuthProvider';
import ReactStars from 'react-stars';
const CourseTimeline = ({ course,fetchCurrCourse }) => {
    const { currentUser } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newDate, setNewDate] = useState('');
    
    const [starsGiven, setStarsGiven] = useState(0);
    useEffect(() => {
        if (course && currentUser) {
          const userRating = course?.ratedBy?.find((e) => e.userId.toString() === currentUser._id.toString());
          setStarsGiven(userRating ? userRating.rating : 0);
        }
      }, [course, currentUser]);
    const getCurrentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };
    const [newEvent, setNewEvent] = useState({ title: '', startTime: getCurrentTime(), duration: '', notesUrl: '' });
    const [editingEvent, setEditingEvent] = useState(null);
    const [error, setError] = useState('');

    const durations = [1, 1.5, 2, 2.5, 3, 3.5, 4]; // Duration options in hours

    const handleAddEventClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsEditing(false);
        setEditingEvent(null);
        setNewDate('');
        setNewEvent({ title: '', startTime: getCurrentTime(), duration: '', notesUrl: '' });
        setError('');
    };

    const handleInputChange = (e, field) => {
        setNewEvent({
            ...newEvent,
            [field]: e.target.value,
        });
    };

    // Helper function to calculate end time
    const calculateEndTime = (startTime, duration) => {
        const [hours, minutes] = startTime.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes + duration * 60;
        const endHours = Math.floor(totalMinutes / 60) % 24;
        const endMinutes = totalMinutes % 60;
        return `${String(endHours).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`;
    };

    const handleAddTimelineEntry = async (e) => {
        e.preventDefault();
        try {
            const endTime = calculateEndTime(newEvent.startTime, parseFloat(newEvent.duration));
            const response = await axios.post(
                `${apiUrl}/course/timeline/event`,
                { courseId: course._id, date: newDate, event: { ...newEvent, endTime } },
                { withCredentials: true }
            );

            console.log('Event added successfully:', response.data);
            handleCloseModal();
            // window.location.reload();
            await fetchCurrCourse();
        } catch (error) {
            console.error('Error adding event:', error);
            setError('There was an issue adding the event. Please try again.');
        }
    };

    const handleEditEventClick = (event, date) => {
        setIsEditing(true);
        setEditingEvent(event);
        setNewDate(date);
        setNewEvent({ ...event, duration: null });
        setIsModalOpen(true);
    };

    const convertTo12HourFormat = (time) => {
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);

        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 or 12 to 12, and 13-23 to 1-11.

        return `${hours}:${minutes} ${period}`;
    };

    const handleEditTimelineEntry = async (e) => {
        e.preventDefault();
        try {
            const endTime = calculateEndTime(newEvent.startTime, parseFloat(newEvent.duration));
            const response = await axios.post(
                `${apiUrl}/course/timeline/event/edit`,
                { eventId: editingEvent._id, courseId: course._id, date: newDate, ...newEvent, endTime },
                { withCredentials: true }
            );

            console.log('Event updated successfully:', response.data);
            handleCloseModal();
            // window.location.reload();
            await fetchCurrCourse();
        } catch (error) {
            console.error('Error updating event:', error);
            setError('There was an issue updating the event. Please try again.');
        }
    };

    const handleRatingChange = async (newRating) => {
        setStarsGiven(newRating);
        try {
            const response = await axios.post(
                `${apiUrl}/course/rateCourse`,
                {
                    courseId: course._id,
                    userId: currentUser._id,
                    rating: newRating
                },
                { withCredentials: true }
            );
            console.log('Course rated successfully:', response.data);
        } catch (error) {
            console.error('Error rating the course:', error);
            setError('There was an issue rating the course. Please try again.');
        }
    };

    return (
        <div className="p-4 font-poppins">
            {/* Render Timeline Entries */}
            {course?.timeline?.length ? (
                course?.timeline?.map((daySchedule, index) => (
                    daySchedule?.events?.length !== 0 && <div key={index} className="mb-6">
                        <h3 className="text-gray-500 font-medium text-sm">{daySchedule.day}</h3>
                        <h2 className="text-2xl font-semibold mb-4">{daySchedule.date}</h2>
                        {daySchedule?.events?.map((event, idx) => (
                            <div key={idx} className="p-4 border border-gray-300 rounded-lg mb-4">
                                <div className='flex items-center justify-between'>
                                    <h4 className="text-xl font-medium my-3">{event.title}</h4>
                                    {(currentUser?.role === "Admin" || (currentUser?.role === "Instructor" && course?.instructor === currentUser._id)) && (
                                        <button
                                            onClick={() => handleEditEventClick(event, daySchedule.date)}
                                            className='flex text-sm p-2 px-4 mx-2 items-center bg-[#f0dafc] cursor-pointer hover:bg-[#f0bafc] rounded-xl'>
                                            <FaEdit />
                                            <p className='mx-2'>Edit</p>
                                        </button>
                                    )}
                                </div>
                                <hr />
                                <div className='my-2 flex flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row justify-between items-center'>
                                    <div className="text-gray-600 flex items-center">
                                        <FaClock />
                                        <p className='mx-3'>IST {convertTo12HourFormat(event.startTime)} - {convertTo12HourFormat(event.endTime)}</p>
                                    </div>
                                    <div className='flex'>
                                        <a href={event.notesUrl} target="_blank" className='flex text-sm p-2 px-4 mx-2 items-center bg-[#f0dafc] cursor-pointer hover:bg-[#f0bafc] rounded-xl'>
                                            <MdNotes />
                                            <p className='mx-2'>Notes</p>
                                        </a>
                                        <a href={course.meetUrl} target='_blank' className='flex text-sm p-2 px-4 mx-2 items-center bg-[#f0dafc] cursor-pointer hover:bg-[#f0bafc] rounded-xl'>
                                            <SiGooglemeet />
                                            <p className='mx-2'>Join</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <p>No events scheduled yet. Add a new date and event to get started!</p>
            )}

            {/* Button to open modal for adding new event */}
            {(currentUser?.role === "Admin" || (currentUser?.role === "Instructor" && course?.instructor === currentUser._id)) && !isEditing && (
                <button
                    onClick={handleAddEventClick}
                    className="mt-4 p-2 bg-purple-400 text-white rounded"
                >
                    Add Event to Timeline
                </button>
            )}
            {currentUser?.role === "Student" &&
                <div className="mt-4 p-4 border border-gray-300 rounded-lg mb-4 bg-gray-100 flex justify-center flex-col items-center">
                    <h4 className="text-xl font-semibold">Rate this Course</h4>
                    <ReactStars
                        count={5}
                        value={starsGiven}
                        onChange={handleRatingChange}
                        size={35}
                        color2={'#ffd700'}
                    />
                    {course?.ratedBy?.find((e)=>e.userId===currentUser._id) && <p className='text-sm'>You rated {starsGiven} stars</p>}
                </div>}

            {/* Modal for Adding or Editing Timeline Entry */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <form
                        onSubmit={isEditing ? handleEditTimelineEntry : handleAddTimelineEntry}
                        className="bg-white p-6 rounded-lg h-fit max-h-[90vh] overflow-y-auto"
                    >
                        <h2 className="text-2xl font-semibold mb-4">{isEditing ? 'Edit Timeline Entry' : 'Add New Timeline Entry'}</h2>
                        {error && <p className="text-red-500">{error}</p>}
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                        <input
                            type="date"
                            name="date"
                            value={newDate}
                            onChange={(e) => setNewDate(e.target.value)}
                            required
                            className="w-full p-2 mb-3 border border-gray-300 rounded"
                            disabled={isEditing} // Disable date editing for existing events
                        />
                        <h3 className="text-xl font-semibold mb-2">Event</h3>
                        <label className="block mb-2 font-medium">Event Title</label>
                        <input
                            type="text"
                            placeholder="Event Title"
                            value={newEvent.title}
                            onChange={(e) => handleInputChange(e, 'title')}
                            required
                            maxLength={30}
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                        />
                        <label className="block mb-2 font-medium">Start Time (IST)</label>
                        <input
                            type="time"
                            placeholder="Start Time"
                            value={newEvent.startTime}
                            onChange={(e) => handleInputChange(e, 'startTime')}
                            required
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                        />
                        <label className="block mb-2 font-medium">Duration</label>
                        <select
                            value={newEvent.duration}
                            onChange={(e) => handleInputChange(e, 'duration')}
                            required
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                        >
                            <option value="">Select Duration</option>
                            {durations.map((duration, index) => (
                                <option key={index} value={duration}>{duration} hours</option>
                            ))}
                        </select>

                        <label className="block mb-2 font-medium">Notes URL</label>
                        <input
                            type="url"
                            placeholder="Notes URL"
                            value={newEvent.notesUrl}
                            onChange={(e) => handleInputChange(e, 'notesUrl')}
                            className="w-full p-2 mb-2 border border-gray-300 rounded"
                            pattern="https?://.+"
                        />

                        <div className="mt-4 flex justify-end">
                            <button
                                type="button"
                                onClick={handleCloseModal}
                                className="mr-4 py-2 px-4 bg-gray-300 rounded"
                            >
                                Cancel
                            </button>
                            <button type="submit" className="py-2 px-4 bg-purple-400 text-white rounded">
                                {isEditing ? 'Update Event' : 'Add Event'}
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default CourseTimeline;
