import React from 'react'
import ComingSoon from './ComingSoon'

const MockInterviews = () => {
  return (
    <>
    <ComingSoon/>
    </>
  )
}

export default MockInterviews