const doubtsData  = [
    {
      id: 1,
      course: "Machine Learning Foundations",
      session: "Session 1",
      question: "What is the difference between supervised and unsupervised learning?",
      file: "https://example.com/file1.pdf",
      solution: null, // Open doubt
      status: "open"
    },
    {
      id: 2,
      course: "Interview Preparation",
      session: "Session 2",
      question: "Can you explain dynamic programming?",
      file: null,
      solution: null, // Open doubt
      status: "open"
    },
    {
      id: 3,
      course: "Web Development",
      session: "Session 3",
      question: "How does React's state management work?",
      file: "https://example.com/file2.pdf",
      solution: "state management makes the state of an app visible in the form of a data structure, improving developers' ability to work with the app. State management libraries provide developers with the tools needed to create the data structures and change them when new actions occur.", // Closed doubt
      status: "closed"
    },
    {
      id: 4,
      course: "Machine Learning Foundations",
      session: "Session 4",
      question: "What are hyperparameters?",
      file: null,
      solution: "Hyperparameters are external configuration variables that data scientists use to manage machine learning model training. Sometimes called model hyperparameters, the hyperparameters are manually set before training a model.", // Closed doubt
      status: "closed"
    }
  ];

  export default doubtsData;