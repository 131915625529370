import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import coverImage from "../assets/coverimg.png";
import { Button } from '@mui/material';
import { loginUser, test } from '../services/LoginService';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../services/AuthProvider';

const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errormsg, setErrormsg] = useState('')
  const navigate = useNavigate()
  const auth = useAuth()
  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      console.log("Logging in User")
      const user = await loginUser(email, password)
      if (user.isValidLogin) {
        auth.setIsAuthenticated(true)
        auth.setCurrentUser(user.user)
        console.log(user.user);
        navigate("/dashboard", { state: { 'userData': user } })
      }
      else{
        setErrormsg("Invalid Credentials")
      }
    } catch (error) {
      setErrormsg("Internal Server Error")
    }
  }

  return (
    <div style={{ marginTop: '1%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', width: '25%', marginLeft: '12%' }}>
          <div style={{ fontSize: 34 }}>
            Login
          </div>
          <TextField
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            style={{ marginTop: 10 }}
            required
            onChange={(e) => { setEmail(e.target.value) }} />
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            style={{ marginTop: 10 }}
            type="password"
            required
            onChange={(e) => { setPassword(e.target.value) }} />
          <p className='my-1 text-red-400'>{errormsg}</p>
          <Button
            type='submit'
            variant='contained'
            style={{ marginTop: 10, backgroundColor: '#cc33ff' }}>
            login
          </Button>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ marginTop: 5, marginLeft: 5, color: '#ac00e6', fontWeight: 500, textDecoration: 'underline' }}>
              <a style={{ cursor: 'pointer' }}>Forgot Password ?</a>
            </p>
          </div>
          <hr style={{ marginTop: 20, color: 'grey' }}></hr>
        </form>
        <div style={{ width: '50%', marginLeft: '10%' }}>
          <img src={coverImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Login;