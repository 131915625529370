const ourCoursesData = {
  "name": "Machine Learning Foundations",
  "timeline": [{
    "day": "Saturday",
    "date": "1 June",
    "events": [
      {
        "title": "Machine Learning Foundations & Gen AI - I",
        "startTime": "11:00 AM",
        "endTime": "12:30 PM"
      },
      {
        "title": "Machine Learning Foundations & Gen AI - II",
        "startTime": "2:00 PM",
        "endTime": "3:30 PM"
      }
    ]
  },
  {
    "day": "Sunday",
    "date": "2 June",
    "events": [
      {
        "title": "Interview Preparation - GTM",
        "startTime": "10:00 AM",
        "endTime": "11:30 AM"
      }
    ]
  },
  {
    "day": "Monday",
    "date": "3 June",
    "events": [
      {
        "title": "Advanced Python Programming",
        "startTime": "9:00 AM",
        "endTime": "10:30 AM"
      },
      {
        "title": "Data Structures & Algorithms",
        "startTime": "11:00 AM",
        "endTime": "12:30 PM"
      }
    ]
  },
  {
    "day": "Tuesday",
    "date": "4 June",
    "events": [
      {
        "title": "Web Development with React",
        "startTime": "1:00 PM",
        "endTime": "2:30 PM"
      },
      {
        "title": "Database Management Systems",
        "startTime": "3:00 PM",
        "endTime": "4:30 PM"
      }
    ]
  },
  {
    "day": "Wednesday",
    "date": "5 June",
    "events": [
      {
        "title": "Cloud Computing Essentials",
        "startTime": "10:00 AM",
        "endTime": "11:30 AM"
      },
      {
        "title": "Big Data Analytics",
        "startTime": "2:00 PM",
        "endTime": "3:30 PM"
      }
    ]
  }
  ]
}

export default ourCoursesData