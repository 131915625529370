import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';

function Dashboard() {
  const [activeSection, setActiveSection] = useState('dashboard');

  return (
    <div className="flex flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row poppins-regular h-screen">
      <Sidebar activeSection={activeSection} setActiveSection={setActiveSection} />
      <div className="overflow-x-hidden w-full overflow-y-scroll min-h-screen">
        {/* <MainContent activeSection={activeSection} /> */}
        <div className="p-1 md:p-1 lg:p-6 xl:p-6 2xl:p-6 bg-white">
        <Outlet/>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
