import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../services/AuthProvider';
import { apiUrl } from '../../constants/ApplicationConstants';


const UsersDisplay = () => {
    const { currentUser } = useAuth();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editUser, setEditUser] = useState({
        email: '',
        userName: '',
        contact: '',
        role: 'Student'
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/user`, { withCredentials: true });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Error fetching users');
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setEditUser({
            email: user.email,
            userName: user.userName,
            contact: user.contact,
            role: user.role
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditUser({
            ...editUser,
            [name]: value
        });
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.patch(`${apiUrl}/user/${selectedUser.userId}`, editUser, { withCredentials: true });
            const updatedUser = response.data;
            setUsers(users.map(user => user._id === updatedUser._id ? updatedUser : user));
            setSuccess('User updated successfully');
            setSelectedUser(null);
            setEditUser({
                email: '',
                userName: '',
                contact: '',
                role: 'Student'
            });
            // window.location.reload();
            await fetchUsers();
        } catch (error) {
            console.error('Error updating user:', error);
            setError('Error updating user');
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-semibold mb-4">Users List</h1>
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr>
                        <th className="border-b py-2 px-4">Email</th>
                        <th className="border-b py-2 px-4">Username</th>
                        <th className="border-b py-2 px-4">Contact</th>
                        <th className="border-b py-2 px-4">Role</th>
                        <th className="border-b py-2 px-4">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user._id}>
                            <td className="text-center border-b py-2 px-4">{user.email}</td>
                            <td className="text-center border-b py-2 px-4">{user.userName}</td>
                            <td className="text-center border-b py-2 px-4">{user.contact}</td>
                            <td className="text-center border-b py-2 px-4">{user.role}</td>
                            <td className="text-center border-b py-2 px-4">
                                {user.role !== "Admin" && <button
                                    onClick={() => handleEditClick(user)}
                                    className="bg-yellow-500 text-white px-2 py-1 rounded"
                                >
                                    Edit
                                </button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Edit User Modal */}
            {selectedUser && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <form onSubmit={handleUpdateUser} className="bg-white p-6 rounded-lg w-[90%] max-w-md">
                        <h2 className="text-2xl font-semibold mb-4">Edit User</h2>
                        {error && <p className="text-red-500">{error}</p>}
                        {success && <p className="text-green-500">{success}</p>}
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={editUser.email}
                            onChange={handleInputChange}
                            required
                            className="w-full p-2 mb-3 border border-gray-300 rounded"
                        />
                        <label htmlFor="userName" className="block text-sm font-medium text-gray-700">Username</label>
                        <input
                            type="text"
                            name="userName"
                            value={editUser.userName}
                            onChange={handleInputChange}
                            required
                            className="w-full p-2 mb-3 border border-gray-300 rounded"
                        />
                        <label htmlFor="contact" className="block text-sm font-medium text-gray-700">Contact</label>
                        <input
                            type="number"
                            name="contact"
                            value={editUser.contact}
                            onChange={handleInputChange}
                            required
                            className="w-full p-2 mb-3 border border-gray-300 rounded"
                        />
                        <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
                        <select
                            name="role"
                            value={editUser.role}
                            onChange={handleInputChange}
                            className="w-full p-2 mb-3 border border-gray-300 rounded"
                        >
                            <option value="Student">Student</option>
                            <option value="Instructor">Instructor</option>
                            <option value="Admin">Admin</option>
                        </select>
                        <div className="flex justify-end">
                            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded mr-2">
                                Update
                            </button>
                            <button
                                type="button"
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                                onClick={() => setSelectedUser(null)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default UsersDisplay;
