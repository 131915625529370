import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import FloatingWhatsappButton from "../components/FloatingWhatsAppButton";
import Chatbot from "../components/chatbot";
import dsa from "../assets/dsa.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Checkout = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
},[])

  const location = useLocation();
  const navigate = useNavigate();
  const course = location.state;
  const gst = course.amount * 0.18;
  const total = gst + course.amount;

  const [razorpayInstance, setRazorpayInstance] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleProceedToPayment = async (e) => {
    e.preventDefault();

    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const phone = document.getElementById("phone").value;

    const response = await fetch("https://api.coursewallah.com/create_order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ coursename: course.name, name, email, phone }),
    });

    const orderData = await response.json();

    // Load the Razorpay script
    const scriptLoaded = await loadRazorpayScript();
    if (!scriptLoaded) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_live_MuwBj8yzziyrhA", // Replace with your Razorpay key ID
      amount: orderData.amount,
      currency: orderData.currency,
      name: "CourseWallah",
      description: `${course.name}`,
      order_id: orderData.id,
      handler: async (response) => {
        try {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            navigate("/home");
          }, 5000);
          console.log("Payment successful:", response);
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
            response;
          const name = document.getElementById("name").value;
          const email = document.getElementById("email").value;
          const phone = document.getElementById("phone").value;

          // Send the payment success data to the backend
          const res = await fetch("https://api.coursewallah.com/success", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              paymentId: razorpay_payment_id,
              orderId: razorpay_order_id,
              signature: razorpay_signature,
              name,
              email,
              phone,
              courseId: course.id,
              amount: total,
            }),
          });

          if (res.ok) {
            console.log("Payment success data sent to backend");
            // You can perform any additional actions here, such as redirecting to a success page
            // or displaying a success message to the user
          } else {
            console.error("Error sending payment success data to backend");
            // You can handle the error case here, such as displaying an error message to the user
          }
        } catch (error) {
          // navigate('/aboutus')
          
        }
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        amount: course.amount,
        gst: gst,
        total: total,
      },
      theme: {
        color: "#3F72FF",
      },
      customer: {
        name: name,
        contact: phone, // Replace with the customer's phone number
        email: email, // Replace with the customer's email address
      },
      
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="checkout-page">
      {error && (
        <div className="fixed top-[100px] right-5 text-[0.8rem] w-[200px] md:w-[300px] p-4 text-white rounded-lg shadow-lg flex justify-between items-center bg-[#f25252]  animate-slide-in-left">
          <p>Payment Failed. Sorry please try again.</p>
          <button onClick={() => setError(false)} className="">
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}
      {success && (
        <div className="fixed top-[100px] right-5 text-[0.8rem] w-[200px] md:w-[300px] p-4 text-white rounded-lg shadow-lg flex justify-between items-center bg-green-500 animate-slide-in-left">
          <p className="">Payment Successful. Our team will contact u shortly.</p>
          <button onClick={() => setSuccess(false)}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      )}



      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="bg-white rounded-lg shadow-lg p-6  w-[600px]">
          <div>
            <div className="flex justify-center ">
              <img src={dsa} alt="" className=" w-[80%] rounded-lg" />
            </div>
            <div className="flex font-semibold justify-between text-[1.2rem] m-2">
              <p>Course Name:</p>
              <p className=" ">{course.name}</p>
            </div>
          </div>
          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900">Order Summary</h2>
            <div className="mt-2 space-y-1 text-sm text-gray-600">
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span>₹{course.amount}</span>
              </div>

              <div className="flex justify-between">
                <span>Tax @ 18%</span>
                <span>₹{gst.toFixed(0)}</span>
              </div>
              <div className="flex justify-between font-medium">
                <span>Total</span>
                <span className="text-[1.1rem]">₹{total.toFixed(0)}</span>
              </div>
            </div>
          </div>
          <form onSubmit={handleProceedToPayment}>
            <div className="mb-4">
              <label
                className="block  font-medium text-gray-700"
                htmlFor="name"
              >
                Name:
              </label>
              <input
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm focus:outline-none"
                type="text"
                id="name"
                name="name"
                placeholder="John Smith"
	  	required
              />
            </div>
            <div className="mb-4">
              <label
                className="block  font-medium text-gray-700"
                htmlFor="cardNumber"
              >
                Phone:
              </label>
              <input
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm focus:outline-none"
                type="text"
                id="phone"
                name="phone"
                placeholder="+91"
	  	required
              />
            </div>
            <div className="mb-4">
              <label
                className="block  font-medium text-gray-700"
                htmlFor="cardNumber"
              >
                Email:
              </label>
              <input
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm focus:outline-none"
                type="text"
                id="email"
                name="email"
                placeholder="@gmail.com"
	  	required
              />
            </div>
            <div className=" ease-in-out duration-300 hover:scale-[1.05] text-center mt-4">
              <button
                type="submit"
                className="w-[80%] bg-purple-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Pay ₹{total.toFixed(0)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
