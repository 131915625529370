import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faGlobe,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

import FloatingWhatsappButton from '../components/FloatingWhatsAppButton'
import Chatbot from '../components/chatbot'

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className="  m-4">
      <div className=" mx-[20px] md:mx-[6rem] h-[100%]  flex flex-wrap montserrat-regular my-[100px]">
        <div className="md:flex-1   flex-grow">
          <div className=" p-5 pt-0 border-[1px] border-[#8f46de] flex flex-col rounded-3xl h-full">
            <p className="tracking-0 mx-1 text-xl mt-5 text-[#8f46de] montserrat-regular font-bold">
              Contact us
            </p>
            <p className="leading-none text-[3rem] montserrat-regular mb-4">
              Get in Touch
            </p>
            <div className=" flex-grow  flex flex-col justify-evenly ">
              <div className="flex">
                <div className="p-4 bg-[#8f46de] h-[60px] w-[60px] rounded-xl shadow flex items-center justify-center">
                  <FontAwesomeIcon
                    className="  text-2xl font-semibold"
                    icon={faPhone}
                    color="white"
                  />
                </div>
                <div className=" ml-4">
                  <p className="text-1xl md:text-2xl font-semibold">Phone:</p>
                  <p className=" text-sm md:text-base">+91 8319883507</p>
                </div>
              </div>

              <div className="   flex">
                <div className="p-4 bg-[#8f46de] h-[60px] w-[60px] rounded-xl shadow flex items-center justify-center">
                  <FontAwesomeIcon
                    className=" text-2xl font-semibold"
                    icon={faEnvelope}
                    color="white"
                  />
                </div>
                <div className=" ml-4">
                  <p className="text-1xl md:text-2xl font-semibold">Email:</p>
                  <p className=" text-sm md:text-base">info@coursewallah.com</p>
                </div>
              </div>

              <div className="  flex">
                <div className="p-4 bg-[#8f46de] h-[60px] w-[60px] rounded-xl shadow flex items-center justify-center">
                  <FontAwesomeIcon
                    className=" text-2xl font-semibold"
                    icon={faGlobe}
                    color="white"
                  />
                </div>
                <div className=" ml-4">
                  <p className="text-1xl md:text-2xl font-semibold">Address:</p>
                  <p className=" text-sm md:text-base">104, Plot 49, Sector 25, Mahadubai Sadan, Ulwe, Navi Mumbai, 410206</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col flex-grow m-10 justify-center items-center">
          <p className="leading-none text-[3rem] montserrat-regular mb-4">
            Contact Us
          </p>
          <div className="h-[100%] w-[90%] p-6 flex flex-col">
            <form>
              <div className="mb-4">
                <label htmlFor="name" className="block text-xl text-gray-700 font-semibold mb-2">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full border-b-2 border-gray-30 text-xl0 focus:outline-none focus:border-blue-500 bg-transparent"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block text-xl text-gray-700 font-semibold mb-2">Phone</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="w-full border-b-2 border-gray-300 text-xl focus:outline-none focus:border-blue-500 bg-transparent"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-xl text-gray-700 font-semibold mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full border-b-2 border-gray-300 text-xl focus:outline-none focus:border-blue-500 bg-transparent"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-xl text-gray-700 font-semibold mb-2">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full border-b-2 border-gray-300 f text-xlocus:outline-none focus:border-blue-500 bg-transparent"
                ></textarea>
              </div>
              <div className="flex justify-center">
                <button className="rounded-tl-[15px] rounded-br-[15px] bg-[#8f46de] text-white p-3 mx-3 text-xl" type="submit">
                  Send
                  <FontAwesomeIcon className="ml-4 transform hover:translate-x-2 transition duration-300" icon={faAngleRight} />
                </button>
              </div>
            </form>
          </div>



        </div>
      </div>
      <FloatingWhatsappButton />
      <Chatbot />

    </div>
  );
};

export default Contactus;
