import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import whyus from "../assets/whyus.png"
import "../styles/courses.css";

const Allcourses = () => {
  return (
    <div>

    <div className="flex flex-wrap  justify-between items-center content-center  my-[40px]">
      <div className="md:flex-1 flex flex-col justify-center md:block"> 
        <div style={{fontWeight:600}} className="text-4xl font-bold pl-[50px] pt-[50px] md:pt-[0px] md:pl-[300px] montserrat-regular sm:leading-[60px] sm:text-[50px] leading-[40px] text-[30px] md:leading-[90px] md:text-[80px]">Why Us</div>
        <ul className=" text-[0.8rem] md:text-[1.2rem] mt-[0.5rem] md:mt-[2rem] pl-[50px] md:pl-[300px] ">
          <li className="flex mt-2"><FontAwesomeIcon className="mt-1 mr-2 text-green-500" icon={faCircleCheck} /><p className='montserrat-regular ml-1 text-2xl'>Live Session</p></li>
          <li className="flex mt-2"><FontAwesomeIcon className="mt-1 mr-2 text-green-500" icon={faCircleCheck} /><p className='montserrat-regular ml-1 text-2xl'>1:1 mentorship</p></li>
          <li className="flex mt-2"><FontAwesomeIcon className="mt-1 mr-2 text-green-500" icon={faCircleCheck} /><p className='montserrat-regular ml-1 text-2xl'>Instructors from top MNC's</p></li>
          <li className="flex mt-2"><FontAwesomeIcon className="mt-1 mr-2 text-green-500" icon={faCircleCheck} /><p className='montserrat-regular ml-1 text-2xl'>Lifetime access to content</p></li>
          <li className="flex mt-2"><FontAwesomeIcon className="mt-1 mr-2 text-green-500" icon={faCircleCheck} /><p className='montserrat-regular ml-1 text-2xl'>Assured Referral</p></li>
        </ul>
      </div>
      <div className="md:flex-1 md:block"><img src={whyus} alt="" /></div>
    </div>


    </div>
  )
}

export default Allcourses;