import React from 'react'

const Privacy = () => {
  return (
    <div className=' p-[3rem] '>
        <div className='p-2 text-[2rem] md:text-[4rem] font-bold font-serif '>
            Privacy Policy
        </div>

        <div className='p-2 md:text-[1rem] font-sans '>
            This Privacy Policy applies to Coursewallah.com. <br />
            Coursewallah.com recognizes the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on Coursewallah.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.
            Information we collect <br />
            Contact information: We might collect your name, email, mobile number, phone number, street, city, state, pincode, country, and IP address.
            Payment and billing information: We might collect your billing name, billing address, and payment method when you buy a course. We NEVER collect your credit card number or credit card expiry date or other details pertaining to your credit card on our website. Credit card information will be obtained and processed by our online payment partner. <br />
            Information you post: We collect information you post in a public space on our website or on a third-party social media site belonging to Coursewallah.com. <br />
            Demographic information: We may collect demographic information about you, pages you like, exams you intend to take, or any other information provided by you during the use of our website. We might collect this as part of a survey also. <br />
            Other information: If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, the duration of time spent on our website, pages accessed, or what site you visit when you leave us. We might also collect the type of mobile device you are using or the version of the operating system your computer or device is running.
            <br />
            We collect information in different ways: <br />


            <ul className=' ml-3 list-disc'>
                <li> We collect information directly from you. We collect information directly from you when you register for an event or buy tickets. We also collect information if you post a comment on our websites or ask us a question through phone or email.</li>
                <li>We collect information from you passively. We use tracking tools like Google Analytics, Google Webmaster, browser cookies, and web beacons for collecting information about your usage of our website.</li>
                <li>We get information about you from third parties. For example, if you use an integrated social media feature on our websites, the third-party social media site will give us certain information about you. This could include your name and email address.</li>
            </ul>
            <br />
            Use of your personal information <br />

            <ul className=' ml-3 list-disc'>
                <li> We use information to contact you: We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.</li>
                <li>We use information to respond to your requests or questions. We might use your information to confirm your registration for a course or any other product offered on Coursewallah.com.</li>
                <li>We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based on your preferences.</li>
                <li>We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.</li>
                <li>We use information for security purposes. We may use information to protect our company, our customers, or our websites.</li>
                <li>We use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. Or, for example, if you buy a course or any other products from us, we'll enroll you in our newsletter.</li>
                <li>We use information to send you transactional communications. We might send you emails or SMS about your account or a product (course) purchase.</li>
                <li>We use information as otherwise permitted by law.</li>
            </ul>

        </div>


    </div>
  )
}

export default Privacy;