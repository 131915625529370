import axios from "axios"
import { apiUrl, hashKey } from "../constants/ApplicationConstants"
import bcrypt from 'bcryptjs'
const salt = bcrypt.genSaltSync(10)

export const loginUser = async (email, password) => {
    const encryptPass = bcrypt.hashSync(password, hashKey)
    const postParams = { "email": email, "password": encryptPass };
    const result = await axios.post(apiUrl + '/login', postParams, { withCredentials: true });
    return result.data;
}

export const test = async () => {
    const result = await axios.post(apiUrl + '/testApi', { withCredentials: true });
    return result.data;
}


