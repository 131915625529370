const validateUsername = (username) => {
    const maxLength = 20; // Limit max length to 20 characters
    const regex = /^[A-Za-z0-9 ]+$/; // Allow only alphanumeric characters and spaces
    return username.length > 0 && username.length <= maxLength && regex.test(username);
};

const validateContact = (contact) => {
    const regex = /^\d{10}$/; // Allow only numbers and ensure it's 10 digits
    return regex.test(contact);
};
export {validateUsername,validateContact}