import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { apiUrl } from '../constants/ApplicationConstants';
import axios from 'axios';
import { useAuth } from '../services/AuthProvider';

const Doubts = ({ ourCourses, doubts }) => {
    const [courses, setCourses] = useState([]);
    const {currentUser} = useAuth();
    const [openExpandedRow, setOpenExpandedRow] = useState(null);
    const [closedExpandedRow, setClosedExpandedRow] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [session, setSession] = useState('');
    const [doubt, setDoubt] = useState('');
    const [files, setFiles] = useState([]);
    const [error, setError] = useState('');
    const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'text/csv', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validFiles = selectedFiles.filter(file => validTypes.includes(file.type) && file.size <= 10 * 1024 * 1024);

        if (selectedFiles.length !== validFiles.length) {
            setError('Some files were not added because they are either unsupported or exceed 10MB.');
        } else {
            setError('');
        }

        if (files.length + validFiles.length > 5) {
            setError('You can only upload a maximum of 5 files.');
        } else {
            setFiles([...files, ...validFiles].slice(0, 5));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (files.length === 0) {
            setError('Please select valid files.');
            return;
        }
        // Process file uploads
        console.log('Files uploaded:', files);
    };

    const toggleExpandRow = (index, type) => {
        if (type === 'open') {
            setOpenExpandedRow(openExpandedRow === index ? null : index);
        } else if (type === 'closed') {
            setClosedExpandedRow(closedExpandedRow === index ? null : index);
        }
    };

    const truncateText = (text, limit) => {
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    };

    const renderDoubtRow = (doubt, index, type) => {
        const isExpanded = type === 'open' ? openExpandedRow === index : closedExpandedRow === index;
        return (
            <React.Fragment key={doubt.id}>
                <tr
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => toggleExpandRow(index, type)}
                >
                    <td className="py-2 px-4 border-b my-2">{doubt.course}</td>
                    <td className="py-2 px-4 border-b my-2">{doubt.session}</td>
                    <td className="py-2 px-4 border-b my-2">
                        {truncateText(doubt.question, 50)}
                    </td>
                    <td className={`p-2 transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`}>
                        <IoIosArrowDown />
                    </td>
                </tr>
                {isExpanded && (
                    <tr className="bg-gray-50">
                        <td colSpan="4" className="p-4 border-b text-gray-700">
                            <div className="mb-2">
                                <strong>Full Question:</strong> {doubt.question}
                            </div>
                            <div className="mb-2">
                                <strong>Course:</strong> {doubt.course}
                            </div>
                            <div className="mb-2">
                                <strong>Session:</strong> {doubt.session}
                            </div>
                            {doubt.file && (
                                <div className="mb-2">
                                    <strong>File:</strong> <a href={doubt.file} target="_blank" rel="noopener noreferrer">View File</a>
                                </div>
                            )}
                            {doubt.solution && (
                                <div className="mb-2">
                                    <strong>Solution:</strong> {doubt.solution}
                                </div>
                            )}
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    };

    useEffect(() => {
        const fetchEnrolledCourses = async () => {
            try {
                const response = await axios.post(`${apiUrl}/course/getEnrolledCourses`, {
                    userId: currentUser._id
                }, { withCredentials: true });
                const data = response.data;
                setCourses(data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        }
        fetchEnrolledCourses();
    }, [])


    return (
        <div>
            <div className="p-3 md:p-3 lg:p-5 xl:p-5 2xl:p-5 mx-2 border-gray-300 border-[1px] rounded-3xl w-full my-3">
                <h2 className="text-2xl font-semibold mb-4">Raise a New Doubt</h2>
                <form className="space-y-4" onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-gray-700">Select a Enrolled Course</label>
                        <select
                            className="mt-2 p-2 border border-gray-300 rounded-lg w-full"
                            value={selectedCourse}
                            required
                            onChange={(e) => setSelectedCourse()}
                        >
                            {courses.map((course, index) => (
                                <option key={index} value={course.title}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-gray-700">Select session</label>
                        <select
                            className="mt-2 p-2 border border-gray-300 rounded-lg w-full"
                            value={session}
                            required
                            onChange={(e) => setSession(e.target.value)}
                        >
                            <option value="" disabled>Select Session</option>
                            {selectedCourse?.timeline?.map((course, index) => (
                                <option key={index} value={course}>
                                    {course.date}
                                </option>
                            ))}
                        </select>
                        {/* <input
                            type="text"
                            className="mt-2 p-2 border border-gray-300 rounded-lg w-full"
                            value={session}
                            onChange={(e) => setSession(e.target.value)}
                        /> */}
                    </div>
                    <div>
                        <label className="block text-gray-700">Doubt</label>
                        <textarea
                            className="mt-2 p-2 border border-gray-300 rounded-lg w-full"
                            rows="4"
                            value={doubt}
                            onChange={(e) => setDoubt(e.target.value)}
                        ></textarea>
                    </div>
                    <div className='flex flex-col'>
                        <input
                            type="file"
                            accept=".pdf, .jpg, .jpeg, .png, .csv, .docx"
                            multiple
                            onChange={handleFileChange}
                        />
                        {files.filter(file => validTypes.includes(file.type) && file.size <= 10 * 1024 * 1024).map((e, ind) => {
                            return <p key={ind}>{e.name}</p>
                        })}
                        {error && <p className="text-red-500">{error}</p>}
                    </div>
                    <button type="submit" className="hover:bg-[#ffcbff] mt-10 w-fit text-sm bg-[#ffdbff] px-4 py-2 rounded-xl flex justify-evenly items-center">
                        <p className='mx-2'>Submit Doubt</p>
                    </button>
                </form>
            </div>

            {/* Section 2: Open Doubts List */}
            <div className="p-3 md:p-3 lg:p-5 xl:p-5 2xl:p-5 mx-2 border-gray-300 border-[1px] rounded-3xl w-full my-3">
                <h2 className="text-2xl font-semibold mb-4">Open Doubts</h2>
                <div className='w-full overflow-scroll'>

                    <table className="min-w-full bg-white border border-gray-200 rounded-xl overflow-hidden">
                        <thead className='bg-[#ffdbff]'>
                            <tr>
                                <th className="py-2 px-4 border-b text-left text-sm font-semibold text-gray-700">Course</th>
                                <th className="py-2 px-4 border-b text-left text-sm font-semibold text-gray-700">Session</th>
                                <th className="py-2 px-4 border-b text-left text-sm font-semibold text-gray-700">Question</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {doubts.filter(d => d.status === 'open').map((doubt, index) => renderDoubtRow(doubt, index, 'open'))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Section 3: Closed Doubts List */}
            <div className="p-3 md:p-3 lg:p-5 xl:p-5 2xl:p-5 mx-2 border-gray-300 border-[1px] rounded-3xl w-full my-3">
                <h2 className="text-2xl font-semibold mb-4">Closed Doubts</h2>
                <div className='w-full overflow-scroll'>
                    <table className="min-w-full bg-white border border-gray-200 rounded-xl overflow-hidden">
                        <thead className='bg-[#ffdbff]'>
                            <tr>
                                <th className="py-2 px-4 border-b text-left text-sm font-semibold text-gray-700">Course</th>
                                <th className="py-2 px-4 border-b text-left text-sm font-semibold text-gray-700">Session</th>
                                <th className="py-2 px-4 border-b text-left text-sm font-semibold text-gray-700">Question</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {doubts.filter(d => d.status === 'closed').map((doubt, index) => renderDoubtRow(doubt, index, 'closed'))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Doubts;
