import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
}));

function PieCenterLabel({ children, perc }) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <>
            <StyledText x={left + width / 2} y={2 * (top + height) / 5} style={{ fontSize: 20 }}>
                {children}
            </StyledText>
            <StyledText x={left + width / 2} y={(top + height) * 3 / 5} style={{ fontSize: 15 }}>
                {perc}
            </StyledText>
        </>
    );
}

const attendance = [
    { id: 1, value: 15, color: '#e8e8e8' },
    { id: 0, value: 10, color: 'magenta' },
]
const assignments = [
    { id: 1, value: 9, color: '#e8e8e8' },
    { id: 0, value: 1, color: 'magenta' },
]

const Tracking = () => {
    return (
        <>
            {/* <div className='flex w-full flex-col'>
                <div className="p-5 m-2 border-gray-300 border-[1px] flex rounded-3xl w-full my-3">
                    <PieChart
                        series={[
                            {
                                data: attendance,
                                innerRadius: 80,

                            },

                        ]}
                        height={250}
                    >
                        <PieCenterLabel perc={Number.parseFloat((attendance[1].value * 100) / (attendance[0].value + attendance[1].value)).toFixed(2) + ' %'}>Attendance</PieCenterLabel>
                    </PieChart>
                </div>
                <div className="p-5 m-2 border-gray-300 border-[1px] flex rounded-3xl w-full my-3">
                <PieChart
                // slotProps={{legend:{hidden:false,direction:'column',position:{vertical:false}}}}
                series={[
                    {
                        data: assignments,
                        innerRadius: 80,
                        },

                        ]}
                        height={200}
                    >
                        <PieCenterLabel
                            perc={Number.parseFloat((assignments[1].value * 100) / (assignments[0].value + assignments[1].value)).toFixed(2) + ' %'}
                        >Assignments</PieCenterLabel>
                    </PieChart>
                </div>
            </div> */}
            <div className='flex w-full justify-evenly flex-col text-center font-inter'>
                <div className="p-5 m-2 border-gray-300 border-[1px] flex flex-col rounded-3xl w-full my-3">
                    <h4 className='font-medium text-2xl my-2'>Attendance</h4>
                    <PieChart
                        series={[{ data: attendance }]}
                        height={200}
                        margin={{ right: 5 }}
                    />
                    <p>You've attended <span className='font-semibold'>20 of 30</span> classes</p>
                </div>
                <div className="p-5 m-2 border-gray-300 border-[1px] flex flex-col rounded-3xl w-full my-3">
                    <h4 className='font-medium text-2xl my-2'>Assignments</h4>
                    <PieChart
                        series={[{ data: assignments }]}
                        height={200}
                        margin={{ right: 5 }}
                    />
                    <p>You've completed <span className='font-semibold'>20 of 30</span> assignments</p>
                </div>
            </div>
        </>
    )
}

export default Tracking