import React from 'react'
import EnrolledCourses from '../EnrolledCourses'
import RecommendedCourses from '../RecommendedCourses'
import Tracking from '../Tracking'
import SearchCourses from '../SearchCourses'
import { useAuth } from '../../services/AuthProvider'
import UsersDisplay from '../AdminItems/UsersDisplay'

const DashboardHome = () => {
    const { currentUser } = useAuth();
    return (
        <>
            <div className='flex w-full flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row'>
                <div className={`w-full md:w-full lg:w-full ${currentUser.role==="Student" && "xl:w-[70%] 2xl:w-[70%]"}`}>
                    <EnrolledCourses />
                    {/* <SearchCourses/> */}
                    {currentUser.role==="Admin" &&<UsersDisplay/>}
                    {currentUser.role==="Student" && <RecommendedCourses />}
                </div>
                {currentUser.role === "Student" &&
                    <div className='w-full md:w-full lg:w-full xl:w-[30%] 2xl:w-[30%]'>
                        <Tracking />
                    </div>
                }
            </div>
        </>
    )
}

export default DashboardHome