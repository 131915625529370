import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import dsa from "../assets/1.png";
import fs from "../assets/2.png";
import ds from "../assets/3.png";
import { Link } from 'react-router-dom';
import { useContext } from 'react';


import Coursepage from '../pages/Coursepage';
import { ScrollContext } from '../App';
import { IoIosStarOutline } from "react-icons/io";

const Coursesoverview = () => {

  const { sectionRef } = useContext(ScrollContext);

  return (
    <div ref={sectionRef} className='bg-[#ffe8ee] py-[100px] mb-2 reddit-sans-regular'  >
      <div className='text-center'>
        <h1 className=' text-[2rem] md:text-[3rem]'>Our Most Popular Courses</h1>
        <p className='mt-2 text-[0.8rem] md:text-[1.2rem] px-2 md:px-[150px]'>Live lectures by industry experts with mock interviews</p>
      </div>
      <div className='  mx-[100px]   flex justify-center gap-2 sm:gap-6 items-center py-2 mt-4 '>

        <button className=" rounded-tl-[8px] rounded-br-[8px]  md:text-[1.2rem] text-[0.8rem]
                 bg-[#f7f7f7] hover:bg-[#8f46de] font-semibold text-[#312f2f] hover:text-white px-5 py-2 shadow-2xl">All Courses
        </button>
        <button className=" rounded-tl-[8px] rounded-br-[8px] md:text-[1.2rem] text-[0.8rem]
                 bg-[#f7f7f7] hover:bg-[#8f46de] font-semibold text-[#312f2f] hover:text-white px-5 py-2 shadow-2xl">DSA
        </button>
        <button className=" rounded-tl-[8px] rounded-br-[8px] md:text-[1.2rem] text-[0.8rem]
                 bg-[#f7f7f7] hover:bg-[#8f46de] font-semibold text-[#312f2f] hover:text-white px-5 py-2 shadow-2xl">Web Development
        </button>
        <button className=" rounded-tl-[8px] rounded-br-[8px] md:text-[1.2rem] text-[0.8rem]
                 bg-[#f7f7f7] hover:bg-[#8f46de] font-semibold text-[#312f2f] hover:text-white px-5 py-2 shadow-2xl">Data science
        </button>

      </div>

      <div className='flex flex-wrap justify-center mx-[40px] md:mx-[100px] gap-[1.5rem] mt-4 '>
        <div className='p-1  border-gray-300 border-[1px] rounded-3xl my-3 bg-white  w-[350px] shadow-xl hover:scale-105 duration-300 transition-transform ease-in-out'>
          <div className=' flex flex-col p-6 justify-between h-full'>
            <div className='h-[180px] bg-slate-100'>
              <img className='rounded-lg inset-0 w-full h-full object-cover' src={dsa} alt="" />
            </div>
            <div className='mt-4 flex text-sm'>
              <div>
                <IoIosStarOutline color='gold' size={18} />
              </div>
              <p className='mx-2'>4.9</p>
            </div>
            <div className='  md:text-xl font-bold'>Data Structures And Algorithms</div>
            <p className='  mt-2 text-[0.8rem] md:text-[1rem]'>Explore all the key DSA concepts.</p>
            <div className=' mt-1  flex justify-between'>
              <p className='font-bold text-sm md:text-base'>₹ 8999.00</p>
              <Link to="/course1">
                <button className=' hover:bg-[#df1f50] text-sm md:text-base hover:text-white px-2 md:px-4 py-1 rounded-tr-[10px] 
                rounded-bl-[10px] border-[#df1f50] border-[1.5px]'> Buy Now</button>
              </Link>
            </div>
          </div>
        </div>



        <div className='p-1  border-gray-300 border-[1px] rounded-3xl my-3 bg-white  w-[350px] shadow-xl hover:scale-105 duration-300 transition-transform ease-in-out'>
          <div className=' flex flex-col p-6 justify-between h-full'>
            <div className='h-[180px] bg-slate-100'>
              <img className='rounded-lg inset-0 w-full h-full object-cover' src={ds} alt="" />
            </div>
            <div className='mt-4 flex text-sm'>
              <div>
                <IoIosStarOutline color='gold' size={18} />
              </div>
              <p className='mx-2'>4.9</p>
            </div>
            <div className='md:text-xl font-bold'>Data Analytics And Data Science</div>
            <p className='  mt-2 text-[0.8rem] md:text-[1rem]'>Unravel complex Analytics and Data Science techniques.</p>
            <div className='flex justify-between'>
              <p className='font-bold text-sm md:text-base'>Launcing soon...</p>
              <button className='hover:bg-[#df1f50] text-sm md:text-base hover:text-white px-2 md:px-4 py-1 rounded-tr-[10px] 
                rounded-bl-[10px] border-[#df1f50] border-[1.5px]'>Buy Now</button>
            </div>
          </div>
        </div>

        <div className='p-1  border-gray-300 border-[1px] rounded-3xl my-3 bg-white  w-[350px] shadow-xl hover:scale-105 duration-300 transition-transform ease-in-out'>
          <div className=' flex flex-col p-6 justify-between h-full'>
            <div className='h-[180px] bg-slate-100'>
              <img className='rounded-lg inset-0 w-full h-full object-cover' src={fs} alt="" />
            </div>
            <div className='mt-4 flex text-sm'>
              <div>
                <IoIosStarOutline color='gold' size={18} />
              </div>
              <p className='mx-2'>4.9</p>
            </div>
            <div>
              <div className='md:text-xl font-bold'>Full Stack Web Development</div>
              <p className='  mt-2 text-[0.8rem] md:text-[1rem]'>Get lost in the immersive world of web creation.</p>
            </div>
            <div className='flex justify-between'>
              <p className='font-bold text-sm md:text-base'>Launching soon...</p>
              <button className=' hover:bg-[#df1f50] text-sm md:text-base hover:text-white px-2 md:px-4 py-1 rounded-tr-[10px] 
                rounded-bl-[10px] border-[#df1f50] border-[1.5px]'> Buy Now</button>
            </div>
          </div>
        </div>







      </div>

    </div>
  )
}

export default Coursesoverview