import React from 'react'
import {Outlet} from 'react-router-dom'

const AssignmentsHome = () => {
  return (
    <>
    
    <Outlet/>
    </>
  )
}

export default AssignmentsHome