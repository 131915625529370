import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../services/AuthProvider';
import axios from 'axios';
import { useEffect, useState } from 'react';

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserLoggedIn = async () => {
      if (auth.isAuthenticated) {
        setIsLoading(false);
        return;
      }
      try {
        const response = await axios.post('/checkIfuserloggedIn');
        console.log('Got 200 response for logged in');
        auth.setIsAuthenticated(true);
        auth.setCurrentUser(response.data);
      } catch (error) {
        console.log('Got error for is logged in');
        auth.setIsAuthenticated(false);
        auth.setCurrentUser(null);
        navigate('/loginPage');
      } finally {
        setIsLoading(false);
      }
    };

    checkUserLoggedIn();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>
  }
  return auth.isAuthenticated ? children : <Navigate to="/loginPage" replace />;
};

export default ProtectedRoute;
